import { SALESESTIMATOR_TYPES } from "../types";

const initState = {
    loading: false,
    orderBy: null,
    order: null,
    page: 0,
    pageSize: 10,
    totalCount: 0,
    deleteProduct: false,
    addProduct: false,
    refreshedProduct: false,
    error: false,
    errorMessage: '',
    successMessage: '',
    deletedProductTitle: '',
    productTitle: '',
    products: [],
    history: false,
};

const salesEstimatorReducer = (state = initState, { type, payload, message, reqData }) => {
    switch (type) {
        case SALESESTIMATOR_TYPES.SET_SALES_ESTIMATOR_TABLE_PARAMS: {
            const { order, orderBy, page, pageSize } = payload;
            return {
                ...state, order, orderBy, page, pageSize
            }
        }
        case SALESESTIMATOR_TYPES.SEARCH_PRODUCTS_ERROR:
        case SALESESTIMATOR_TYPES.DELETE_PRODUCT_ERROR:
        case SALESESTIMATOR_TYPES.REFRESH_PRODUCT_ERROR: {
            const errorMessage = message;
            return {
                ...state, loading: false, error: true, errorMessage
            }
        }
        case SALESESTIMATOR_TYPES.FETCH_PRODUCTS_SUCCESS: {
            const { totalCount, page, history = false } = payload;
            return {
                ...state, products: payload.rows, totalCount, history: Boolean(history), loading: false
            }
        }
        case SALESESTIMATOR_TYPES.SEARCH_PRODUCTS: {
            return {
                ...state, loading: true
            }
        }
        case SALESESTIMATOR_TYPES.SEARCH_PRODUCTS_SUCCESS: {
            const successMessage = message;
            const productTitle = reqData?.meta?.query;
            return {
                ...state, addProduct: true, addedProductTitle: productTitle, loading: false, successMessage
            }
        }
        case SALESESTIMATOR_TYPES.SET_ADD_PRODUCT_FLAG: {
            return { ...state, addProduct: false, addedProductTitle: "" }
        }
        case SALESESTIMATOR_TYPES.DELETE_ESTIMATOR_PRODUCT_SUCCESS: {
            const { id } = reqData.meta;
            const deletedProduct = state.products.find((e) => e.id === id);
            const deletedProductTitle = deletedProduct?.product?.title;
            const updated = state.products.filter((e) => e.id !== id);
            let pageIndex;

            if (updated.length === 0 && state.page !== 0) {
                pageIndex = state.page - 1
            } else {
                pageIndex = state.page
            }

            return { ...state, deleteProduct: true, deletedProductTitle: deletedProductTitle, loading: false, products: updated, page: pageIndex, totalCount: --state.totalCount }
        }
        case SALESESTIMATOR_TYPES.SET_DELETE_PRODUCT_FLAG: {
            return { ...state, deleteProduct: false, deletedProductTitle: "" }
        }
        case SALESESTIMATOR_TYPES.REFRESH_PRODUCT_SUCCESS: {
            const { id } = reqData.meta;
            const refreshedProduct = state.products.find((e) => e.id === id);
            const refreshedProductTitle = refreshedProduct?.product?.title;
            const successMessage = message;
            return {
                ...state, refreshedProduct: true, productTitle: refreshedProductTitle, loading: false, successMessage
            }
        }
        case SALESESTIMATOR_TYPES.SET_REFRESH_PRODUCT_FLAG: {
            return { ...state, refreshedProduct: false, productTitle: "" }
        }
        default:
            return state;
    }
}

export default salesEstimatorReducer;
