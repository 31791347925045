export const FETCH_CATEGORIES = '@calculator/FETCH_CATEGORIES';
export const GET_AMAZON_CATEGORIES = '@calculator/GET_AMAZON_CATEGORIES';
export const GET_BOL_CATEGORIES = '@calculator/GET_BOL_CATEGORIES';
export const FETCH_FBA = '@calculator/FETCH_FBA';
export const GET_FBA = '@calculator/GET_FBA';
export const FETCH_LVB = '@calculator/FETCH_LVB';
export const GET_LVB = '@calculator/GET_LVB';
export const FETCH_BOL_PACKAGE_SIZES = '@calculator/FETCH_BOL_PACKAGE_SIZES';
export const GET_BOL_PACKAGE_SIZES = '@calculator/GET_BOL_PACKAGE_SIZES';
export const CREATE_BOL_PACKAGE_SIZE = '@calculator/CREATE_BOL_PACKAGE_SIZE';
export const CREATE_BOL_PACKAGE_SIZE_SUCCESS = '@calculator/CREATE_BOL_PACKAGE_SIZE_SUCCESS';
export const CREATE_BOL_PACKAGE_SIZE_ERROR = '@calculator/CREATE_BOL_PACKAGE_SIZE_ERROR';
export const EDIT_BOL_PACKAGE_SIZE_SUCCESS = '@calculator/EDIT_BOL_PACKAGE_SIZE_SUCCESS';
export const EDIT_BOL_PACKAGE_SIZE_ERROR = '@calculator/EDIT_BOL_PACKAGE_SIZE_ERROR';
export const DELETE_BOL_PACKAGE_SIZE = '@calculator/DELETE_BOL_PACKAGE_SIZE';
export const DELETE_BOL_PACKAGE_SIZE_SUCCESS = '@calculator/DELETE_BOL_PACKAGE_SIZE_SUCCESS';
export const CLOSE_BOL_PACKAGE_SIZE_MODAL = '@calculator/CLOSE_BOL_PACKAGE_SIZE_MODAL';