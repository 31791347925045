import { COMMON_TYPES } from '../types';
import {toast} from 'react-toastify';

const initState = {
  error: '',
  notification: {},
  loading: false,
  demoIsOpen: false,
}

const notification = {
  success: 'Success',
  error: 'Error',
}

const commonReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case COMMON_TYPES.GET_SAVING_DATA:
      return {
        ...state,
        savingData: true,
      }
    case COMMON_TYPES.REQ_SAVING_DATA:
      return {
        ...state,
        savingData: false,
      }
    case COMMON_TYPES.SHOW_NOTIFICATION: {
      const notificationType = payload.type
        .split('_')
        .pop()
        .toLowerCase()
      const messageText = payload.message || notification[notificationType]
      const fn = toast[notificationType];

      if(typeof fn === 'function') {
        fn(messageText);
      }
      return {
        ...state,
        notification: { messageText, notificationType },
      }
    }
    case COMMON_TYPES.CLEAR_NOTIFICATION: {
      return {
        ...state,
        notification: {},
      }
    }
    case COMMON_TYPES.SET_LOADING:
      return {
        ...state,
        loading: true,
      }
    case COMMON_TYPES.UNSET_LOADING:
      return {
        ...state,
        loading: false,
      }
    case COMMON_TYPES.OPEN_DEMO: {
      return { ...state, demoIsOpen: true }
    }
    case COMMON_TYPES.CLOSE_DEMO: {
      return { ...state, demoIsOpen: false }
    }
    default:
      return state;
  }
}

export default commonReducer;