import React, {useState} from 'react';
import {createPayPalSubscription, updatePayPalDefaultPaymentMethod} from "../../store/actions/profile";
import {Button, withStyles} from "@material-ui/core";
import {PAYPAL} from "../../constants/paymentMethods";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {usePaymentFormStyles} from "./styles/paymentFormStyles";
import {getErrorMessage} from "../../utils";

const StyledSubmitButton = withStyles((theme) => ({
  root: {
    marginTop: 'auto',
    backgroundColor: theme.palette.systemColors.gold.primary,
    color: theme.palette.systemColors.white.main,
    width: '100%',
    height: '48px',
    fontSize: '18px',
    fontWeight: '700',
    textTransform: 'capitalize',

    '&.MuiButton-root.Mui-disabled': {
      color: 'white',
      opacity: '.4',
    }
  }
}))(Button);

export default function PayPal({isEdit, isUpdate}) {
  const {t} = useTranslation();
  const paymentFromStyles = usePaymentFormStyles();
  const [loading, setLoading] = useState(false);
  const shouldCancelAtTheEndOfCycle = useSelector(state=> state.authReducer?.user?.subscription?.shouldCancelAtTheEndOfCycle);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      if(shouldCancelAtTheEndOfCycle) {
        return alert(t("Already cancel still active"));
      }

      setLoading(true);
      const payload = (isEdit || isUpdate) ? {type: PAYPAL} : undefined;
      const promiseFN = (isEdit || isUpdate) ? updatePayPalDefaultPaymentMethod: createPayPalSubscription;
      const {link} = await promiseFN(payload);
      window.location.replace(link);
    } catch (e) {
      setLoading(false);
      const error = getErrorMessage(e);
      const message = (isEdit || isUpdate) ?
        t('Update paypal payment method error', {error}) :
        t('Create paypal subscription error', {error});
      alert(message);
      console.log(e);
    }
  }

  return (
    <form className={paymentFromStyles.form} onSubmit={handleSubmit}>
      <StyledSubmitButton color="primary" type="submit" disabled={loading}>
        {t("Confirm")}
      </StyledSubmitButton>
    </form>
  )
}