export const PRODUCT_FINDER_ROUTES = {
      NICHE_PICKR: "/product-finder/niche-pickr",
      SALES_PICKR: "/product-finder/sales-pickr",
      SALES_ESTIMATOR: "/sales-estimatr"
};

export const ADD_ONS_ROUTES = {
      ECWID: "/add-ons/ecwid"
};

export const SETTINGS_ROUTES = {
      PROFILE: "/settings/profile/my-account",
      SUBSCRIPTION: "/settings/subscription"
};

export const SALES_ESTIMATOR_ROUTE = "/sales-estimatr";
export const ALIBABA_SOURCER_ROUTE = "/alibaba-sourcr";
export const CALCULATOR_ROUTE = "/calculatr";