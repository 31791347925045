import {makeStyles, createStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => createStyles({
  '@global': {
    body: {margin: "0", padding: "0", fontFamily: theme.typography.fontFamily},
    a: {textDecoration: "none"},
    relative: {position: "relative"},
    loader: {
      top: "0",
      right: "0",
      position: "absolute",
      display: "flex",
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,
      backgroundColor: "rgba(255,255,255,0.3)"
    },
  }
}));

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;