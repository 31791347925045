import React, {useEffect} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
//eslint-disable-next-line
import i18n from './translations/i18n';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import PrivateRoute from './components/PrivateRoute';
import {fetchMe, setInitialized} from "./store/actions/auth";
import {useDispatch, useSelector} from "react-redux";
import makeStyles from '@material-ui/core/styles/makeStyles';
import { LinearProgress } from '@material-ui/core';

import { ThemeProvider } from '@material-ui/styles';
import {defaultTheme} from "./constants/theme";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import ChoosePlan from "./views/Plans/ChoosePlan";
import ChoosePaymentMethod from "./components/ChoosePaymentMethod";
import IdealReturn from "./components/ChoosePaymentMethod/IdealReturn";
import PayPalReturn from "./components/ChoosePaymentMethod/PayPalReturn";
import GlobalStyles from "./AppStyles";
import MailButton from "./components/MailButton";
import AuthRoute from "./components/AuthRoute";
import SessionTimeout from "./components/SessionTimeout/SessionTimeout";

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout/DefaultLayout'));
const LoginRegistrationForm = React.lazy(() => import('./components/LoginRegistrationForm'));

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '50vh',
  },
  colorPrimary: {
    backgroundColor: defaultTheme.palette.systemColors.blue.primary,
  },
  barColorPrimary: {
    backgroundColor: defaultTheme.palette.systemColors.blue.soft,
  },
}));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_SECRET);

const App = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const isInitialized = useSelector(store => store.authReducer.isInitialized);
  const isUser = useSelector((store) => store.authReducer.user);

  useEffect(()=> {
    const token = localStorage.getItem('token');
    if(token) {
      return dispatch(fetchMe());
    }
    dispatch(setInitialized());
  }, []);

  if (!isInitialized) {
    return <div className={styles.root}>
      <LinearProgress
        classes={{
          colorPrimary: styles.colorPrimary,
          barColorPrimary: styles.barColorPrimary,
        }}
      />
    </div>
  }

  return (
    <Elements stripe={stripePromise}>
      <BrowserRouter>
          <React.Suspense fallback={<LinearProgress
            classes={{
              colorPrimary: styles.colorPrimary,
              barColorPrimary: styles.barColorPrimary,
            }}
          />}>
            <ThemeProvider theme={defaultTheme}>
            {isUser && localStorage.getItem("token") && <SessionTimeout />}
              <GlobalStyles/>
              <Switch>
                <Route path="/login"><LoginRegistrationForm isLogin/></Route>
                <Route path="/register"><LoginRegistrationForm /></Route>
                <Route path="/forgot-password"><LoginRegistrationForm isForgot/></Route>

                <AuthRoute path="/choose-payment-method" component={ChoosePaymentMethod} />
                <AuthRoute path="/ideal-success" component={IdealReturn} />
                <AuthRoute path="/paypal-success" component={PayPalReturn} />
                <AuthRoute path="/paypal-cancel" component={PayPalReturn} componentProps={{isCancel: true}} />
                {/*<AuthRoute path="/choose-plan" component={ChoosePlan} />*/}

                <PrivateRoute path="/" name="Home" component={DefaultLayout} />
              </Switch>
              <MailButton/>
              <ToastContainer autoClose={5000} pauseOnFocusLoss={false} pauseOnHover={false}/>
            </ThemeProvider>
          </React.Suspense>
      </BrowserRouter>
    </Elements>
  );
};

export default App;