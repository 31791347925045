import { call, fork, put, take } from 'redux-saga/effects';
import { COMMON_TYPES, HTTP_TYPES, RESPONSE_TYPES } from '../types';
import { setLoadingData, unsetLoadingData } from '../actions/common';
import isArray from 'lodash';
import result from 'lodash';
import {request} from '../../utils/agent';

function* sendRequest(data, responseType, loadingType) {
  try {
    if (loadingType) {
      yield put({type: loadingType})
    }
    yield put(setLoadingData())
    const res = yield call(request, data)
    if (responseType) {
      yield put({ type: responseType[0], payload: res.data, reqData: data});
    }
    //TODO refactor this not all acitons have success callback
    if (!data.success) return
    let successCallbacks = [data.success]

    if (isArray(data.success)) {
      successCallbacks = data.success
    }

    for (let i = 0; i < successCallbacks.length; i += 1) {
      yield put(successCallbacks[i](res.data))
    }
    yield put(unsetLoadingData())
  } catch (error) {
    let errorCallbacks
    yield put(unsetLoadingData())
    if (data.error) {
      errorCallbacks = [data.error]
      if (isArray(data.error)) {
        errorCallbacks = data.error
      }
    }
    if (!error.response || (error.response && !error.response.data)) {
      return
    }
    if (responseType) {
      yield put({
        type: responseType[1],
        message:
          error.response.data.message ||
          (error.response.data.raw && error.response.data.raw.message),
        errObj:error.response.data.fields && error.response.data,
      })
    }

    if (!data.error) {
      return
    }

    const errorText = isArray(error.response.data.message)
      ? JSON.stringify(result(error.response.data.message[0], 'constraints'))
      : error.response.data.message
    for (let i = 0; i < errorCallbacks.length; i += 1) {
      yield put(errorCallbacks[i](errorText))
    }
  }
}

function* watchSendRequest() {
  while (true) {
    const { payload, responseType, loadingType } = yield take(HTTP_TYPES.REQUEST);
    yield fork(sendRequest, { ...payload }, responseType, loadingType);
  }
};

function* watchActions() {
  while(true) {
    const actionsTypes = Object.values(RESPONSE_TYPES);
    const { type, message } = yield take(actionsTypes)
    yield put({
      type: COMMON_TYPES.SHOW_NOTIFICATION,
      payload: { type, message },
    })
  }
}

export const http = [fork(watchSendRequest), fork(watchActions)];
