import {AUTH_TYPES, HTTP_TYPES} from '../types';

export const fetchAuth = ({email, password, name, billPeriod, planName, isTrial, addons, isLogin, rememberMe = false, isTreeMonthOffer=false}) => {
  const successType = isLogin ? AUTH_TYPES.AUTH_SUCCESS : AUTH_TYPES.AUTH_CREATE_SUCCESS;
  const url = `/auth/${isLogin ? 'login' : 'register'}`;

  const data = {
    email,
    password,
  };

  if(!isLogin) {
    data.isTrial = isTrial;
    data.billingPeriod = billPeriod;
    data.planType = planName;
    data.addons = addons;
  }

  if(name) {
    data.name = name;
  }

  if(rememberMe) {
    data.remember = rememberMe;
  }

  if(isTreeMonthOffer) {
    data.isTreeMonthOffer = true;
  }

  return {
    type: HTTP_TYPES.REQUEST,
    loadingType: AUTH_TYPES.AUTH_FETCH,
    responseType: [
      successType,
      AUTH_TYPES.AUTH_ERROR
    ],
    payload: {
      method: 'post',
      url,
      data,
    }
  }
}

export const setUser = (user) => {
  return {
    type: AUTH_TYPES.SET_USER,
    payload: {user}
  }
}

export const recoverByEmail = (email) => {
  return {
    type: HTTP_TYPES.REQUEST,
    loadingType: AUTH_TYPES.AUTH_FETCH,
    responseType: [
      AUTH_TYPES.AUTH_RECOVER_SUCCESS,
      AUTH_TYPES.AUTH_ERROR
    ],
    payload: {
      method: 'post',
      url: '/auth/recover',
      data: {email},
    }
  }
}

export const setInitialized = () =>({
  type: AUTH_TYPES.AUTH_SET_INITIALIZED
})

export const clearFormErrorsSuccessMessage = () => ({
  type: AUTH_TYPES.CLEAR_ERROR_SUCCESS_MESSAGES
})

export const fetchMe = () => ({
  type: HTTP_TYPES.REQUEST,
  loadingType: AUTH_TYPES.AUTH_FETCH,
  responseType: [
    AUTH_TYPES.AUTH_SUCCESS,
    AUTH_TYPES.AUTH_ERROR
  ],
  payload: {
    method: 'get',
    url: '/user/me',
  }
});

export const refreshAuth = () => ({
  type: HTTP_TYPES.REQUEST,
  loadingType: AUTH_TYPES.AUTH_FETCH,
  responseType: [
    AUTH_TYPES.AUTH_SUCCESS,
    AUTH_TYPES.AUTH_REFRESH_ERROR
  ],
  payload: {
    method: 'get',
    url: '/auth/refresh',
  }
});

export const logout = (id) => ({
  type: HTTP_TYPES.REQUEST,
  loadingType: AUTH_TYPES.AUTH_FETCH,
  responseType: [
    AUTH_TYPES.AUTH_SUCCESS,
    AUTH_TYPES.AUTH_ERROR
  ],
  payload: {
    method: 'get',
    url: `/auth/expireToken?userId=${id}`,
  }
});