export const AUTH_FETCH = '@auth/AUTH_FETCH';
export const AUTH_SUCCESS = '@auth/AUTH_SUCCESS';
export const AUTH_CREATE_SUCCESS = '@auth/AUTH_CREATE_SUCCESS';
export const AUTH_ERROR = '@auth/AUTH_ERROR';
export const AUTH_REFRESH = '@auth/AUTH_REFRESH';
export const AUTH_RECOVER_SUCCESS = '@auth/AUTH_RECOVER_SUCCESS';
export const AUTH_CLEAR = '@auth/AUTH_CLEAR'
export const AUTH_REFRESH_ERROR = '@auth/AUTH_REFRESH_ERROR'
export const AUTH_SET_INITIALIZED = '@auth/AUTH_SET_INITIALIZED';
export const CLEAR_ERROR_SUCCESS_MESSAGES = '@auth/CLEAR_ERROR_SUCCESS_MESSAGES';
export const SET_USER = '@auth/SET_USER';