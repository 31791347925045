import {PROFILE_TYPES} from "../types";

const initState = {
  paymentMethod: null,
  updatedFields: [],
  loading: false,
  done: false,
  error: false,
  errorMessage: '',
  errorObj: null,
  successMessage: '',
};

const profileReducer = (state = initState, { type, payload, message, errObj }) => {
  switch(type) {
    case PROFILE_TYPES.UPDATE_ACCOUNT_INFO_FETCH:
    case PROFILE_TYPES.UPDATE_DEFAULT_PAYMENT_METHOD_FETCH:
    case PROFILE_TYPES.DEFAULT_PAYMENT_METHOD_FETCH: {
      return {
        ...state, loading: true,
      }
    }

    case PROFILE_TYPES.UPDATE_DEFAULT_PAYMENT_METHOD_SUCCESS:
    case PROFILE_TYPES.UPDATE_ACCOUNT_INFO_SUCCESS:
    case PROFILE_TYPES.DEFAULT_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state, loading: false, done: true, paymentMethod: payload.paymentMethod, error: false, errorObj: null, updatedFields: payload.updatedFields || []
      }
    }

    case PROFILE_TYPES.UPDATE_DEFAULT_PAYMENT_METHOD_ERROR:
    case PROFILE_TYPES.UPDATE_ACCOUNT_INFO_ERROR:
    case PROFILE_TYPES.DEFAULT_PAYMENT_METHOD_ERROR: {
      const errorMessage = message;
      const errorObj = errObj;
      return {...state,done: true, loading: false, error: true, errorMessage, successMessage: '', updatedFields: [], errorObj}
    }

    case PROFILE_TYPES.UPDATE_ACCOUNT_INFO_SUCCESS: {
      return {
        ...initState, updatedFields: payload.updatedFields, done: true, successMessage: payload.successMessage
      }
    }

    case PROFILE_TYPES.DISABLE_DONE_FLAG: {
      return {
        ...state, done: false
      }
    }

    case PROFILE_TYPES.CLEAR_ERROR_SUCCESS_MESSAGES: {
      return {...state, error: false, errorMessage: '', errorObj: null, successMessage: '', done: false}
    }

    default:
      return state;
  }
}

export default profileReducer;