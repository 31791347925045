import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {IconButton} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

export default function SimpleDialog(props) {
  const { onClose = () => {}, open, title, rootClass, titleClass, closeButtonClass, hasCloseIcon = false, fullWidth = false, fullScreen = false } = props;

  return (
    <Dialog onClose={() => onClose(false)} aria-labelledby="simple-dialog-title" open={open} fullWidth={fullWidth} fullScreen={fullScreen} className={rootClass}>
      {title &&
        <DialogTitle id="simple-dialog-title" disableTypography className={titleClass}>{title}
          {hasCloseIcon &&
            <IconButton aria-label="close" className={closeButtonClass} onClick={() => onClose(false)}>
              <CloseIcon />
            </IconButton>
          }
        </DialogTitle>
      }
      {props.children}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ]),
  rootClass: PropTypes.string,
  titleClass: PropTypes.string,
  closeButtonClass: PropTypes.string,
  hasCloseIcon: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};