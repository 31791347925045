import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import {useSelector} from "react-redux";
import {STATUS_CANCELLED} from "../../constants/paymentMethods";

const allowedRoutesForSubscribedUser = [
  '/',
  '/settings/profile/payment-info',
  '/settings/profile/my-account',
  '/settings/subscription'
];


const PrivateRoute = ({ component: Component, ...rest }) => {
  const email = useSelector(state => state.authReducer.user?.email);
  const active = useSelector(state => state.authReducer.user?.subscription?.active);
  const stripeSub = useSelector(state => state.authReducer.user?.subscription?.stripeSubscriptionId);
  const paypalSub = useSelector(state => state.authReducer.user?.subscription?.paypalSubscriptionId);
  const billingPeriod = useSelector(state=> state.authReducer.user?.subscription?.billingPeriod);
  const planId = useSelector(state=> state.authReducer.user?.planId);
  const isCanceledSubscription = billingPeriod || planId;
  const subscriptionId = stripeSub || paypalSub;
  const status = useSelector(state => state.authReducer.user?.subscription?.status);
  const location = useLocation();

  if(email) {
    /**
     * Temp Commented the below code as for the first user story, client wants only to direct to the 
     * niche-finder page
     */
    // if(active) {
    //   return <Route {...rest}><Component /></Route>
    // }

    // if(subscriptionId || (status && STATUS_CANCELLED !== status) ) {
    //   return allowedRoutesForSubscribedUser.includes(location.pathname) ? <Route {...rest}><Component /></Route> :
    //     <Redirect to={{pathname: allowedRoutesForSubscribedUser[3], state: {from: location}}}/>
    // }

    // if(isCanceledSubscription && allowedRoutesForSubscribedUser[3] === location.pathname) {
    //   return <Route {...rest}><Component /></Route>
    // }

    // return <Redirect to={{pathname: '/choose-payment-method', state: {from: location}}} />
    return <Route {...rest}><Component /></Route>

  }

  return <Redirect to={{pathname: '/login', state: {from: location}}} />
};

export default PrivateRoute;