import { COMMON_TYPES } from '../types';

export const getSavingData = () => ({
  type: COMMON_TYPES.GET_SAVING_DATA
})

export const reqSavingData = () => ({
  type: COMMON_TYPES.REQ_SAVING_DATA
})

export const setLoadingData = () => ({
  type: COMMON_TYPES.SET_LOADING
})

export const unsetLoadingData = () => ({
  type: COMMON_TYPES.UNSET_LOADING
})

export const showNotification = (type, message) => ({
  type: COMMON_TYPES.SHOW_NOTIFICATION,
  payload: { message, type }
})

export const clearNotification = () => ({
  type: COMMON_TYPES.CLEAR_NOTIFICATION
})

export const openDemo = () => ({
  type: COMMON_TYPES.OPEN_DEMO,
})

export const closeDemo = () => ({
  type: COMMON_TYPES.CLOSE_DEMO,
})