import { combineReducers } from 'redux';
import commonReducer from './common';
import keywordsReducer from './keywords';
import authReducer from './auth';
import salesSpyReducer from "./sales-spy";
import registerPlanReducer from "./register-plan"
import profileReducer from "./profile";
import calculatorReducer from "./calculator";
import salesEstimatorReducer from "./sales-estimator";
import AlibabaReducer from "./alibaba";

export default combineReducers({
  profileReducer,
  commonReducer,
  keywordsReducer,
  authReducer,
  salesSpyReducer,
  registerPlanReducer,
  calculatorReducer,
  salesEstimatorReducer,
  AlibabaReducer
});
