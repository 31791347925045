export const MONTHLY = 'monthly';
export const ANNUALLY = 'annually';

export const TRIAL = 'Trial';

const PREMIUM = 'Premium';
export const BEGINNERS = 'Beginner';
const ENTERPRISE = 'Enterprise';


export const ECWID_PLANS = {
  [TRIAL]: 'E-PICKR_FREE_DEMO',
  [BEGINNERS]: 'E-PICKR_VENTURE_GOLD',
  [PREMIUM]: 'E-PICKR_BUSINESS_GOLD',
  [ENTERPRISE]: 'E-PICKR_UNLIMITED_GOLD',
}

export const ADDONS = ['ecwid'];

const ESSENTIAL = 'Essential';
const PLUS = 'Plus';
const ULTIMATE = 'Ultimate';

export const MARKETING_PLANS = {
 [ESSENTIAL]: BEGINNERS,
 [PLUS]: PREMIUM,
 [ULTIMATE]: ENTERPRISE
};

export const MARKETING_PLANS_BY_PLAN = {
  [BEGINNERS]: ESSENTIAL,
  [PREMIUM]: PLUS,
  [ENTERPRISE]: ULTIMATE
};


export const PLANS_OVERVIEW = {
  [BEGINNERS]: {
    marketingName: ESSENTIAL,
    name: BEGINNERS,
    pricePerYear: 150,
    pricePerMonth: 17.50,
    ecwidPricePerMonth: 10,
    ecwidPricePerYear: 80,
    currency: 'EUR',
    discount: 60,
    features: [
      '500 Nichepickr Product Insights',
      '10 Salespickr Tracking Insights',
      'Profile Customization Tools',
      'Industry & Performance Updates',
      'Beta-test new tools & save money on your monthly costs!'
    ],
    ecwidHeader: 'Enjoy support, advanced SEO and social media tools, plus:',
    ecwidFeatures: [
      'Free website with a built-in online store',
      'WordPress, Wix, Weebly, Squarespace, Joomla, Blogger en Tumblr integration',
      'Mobile Point-of-Sale',
      'Store and inventory management apps',
      'E-goods: 25GB per file',
      'Abandoned cart saver (automatically save and recover abandoned sales)',
      'Discount coupons',
      'Automated tax calculations',
      'Exclusive access to the app marketplace',
    ],
  },
  [PREMIUM]: {
    marketingName: PLUS,
    name: PREMIUM,
    pricePerYear: 200,
    pricePerMonth: 23.50,
    ecwidPricePerMonth: 20,
    ecwidPricePerYear: 190,
    currency: 'EUR',
    discount: 80,
    features: [
      '2000 NichePickr product Insights',
      '20 SalesPickr Tracking Insights',
      'Profile Customization Tools',
      'Industry & Performance Updates',
      'Beta-test new tools & save money on your monthly costs!'
    ],
    ecwidHeader: 'Everything that is included in the Essential Package, plus:',
    ecwidFeatures: [
      'App Marketplaces',
      'E-goods: 25 GB per file',
      'Wholesale price groups',
      'Google Shopping',
      'eBay US integration',
      'Selling on Amazon and eBay',
      'Customer service'
    ],
  },
  [ENTERPRISE]: {
    marketingName: ULTIMATE,
    name: ENTERPRISE,
    pricePerYear: 250,
    pricePerMonth: 29.5,
    ecwidPricePerMonth: 50,
    ecwidPricePerYear: 500,
    currency: 'EUR',
    discount: 100,
    features: [
      '4000 NichePickr product Insights',
      '50 SalesPickr Tracking Insights',
      'Profile Customization Tools',
      'Industry & Performance Updates',
      'Beta-test new tools & save money on \n' +
      'your monthly costs!'
    ],
    ecwidHeader: 'Everything that is included in the Plus Package and the following additions:',
    ecwidFeatures: [
      'Point of sale',
      'E-goods: 25 GB per file',
      'Custom shopping app',
      'Square POS integration',
      'Priority support (Facebook live chat, phone, email and website chat)',
      '12 hours of custom development',
    ],
  },
};

export const PLANS = [
  {
    image: '/images/plans-table/plans-images/circle.jpg',
    marketingName: ESSENTIAL,
    name: BEGINNERS,
    pricePerYear: 150,
    pricePerMonth: 17.50,
    ecwidPricePerMonth: 10,
    ecwidPricePerYear: 80,
    currency: 'EUR',
    discount: 60
  },
  {
    image: '/images/plans-table/plans-images/airplane.jpg',
    marketingName: PLUS,
    name: PREMIUM,
    pricePerYear: 200,
    pricePerMonth: 23.50,
    ecwidPricePerMonth: 20,
    ecwidPricePerYear: 190,
    currency: 'EUR',
    discount: 80
  },
  {
    image: '/images/plans-table/plans-images/rocket.jpg',
    marketingName: ULTIMATE,
    name: ENTERPRISE,
    pricePerYear: 250,
    pricePerMonth: 29.50,
    ecwidPricePerMonth: 50,
    ecwidPricePerYear: 500,
    currency: 'EUR',
    discount: 100
  },
];

export const PLANS_FEATURES = [
  {
    sectionName: 'Extras',
    features: [
      {
        name: 'E-pickr community',
        beginner: true,
        premium: true,
        enterprise: true,
        tooltip: 'Access to the e-pickr community',
      },
      {
        name: 'Expert’ support',
        beginner: true,
        premium: true,
        enterprise: true,
        tooltip: 'Instant support from our experts',
      },
    ]
  },
  {
    sectionName: 'Tools',
    features: [
      {
        name: 'Niche Pickr',
        beginner: 20,
        premium: 10,
        enterprise: 20,
        tooltip: 'Count of searches on niche finder per month',
      },
      {
        name: 'Sales Pickr',
        beginner: 20,
        premium: 20,
        enterprise: 50,
        tooltip: 'Count of stored products for historical performance data',
      },
      {
        name: 'Niche Rater',
        beginner: true,
        premium: true,
        enterprise: true,
        tooltip: 'Up-to-date data and scores for potential niches',
      },
      {
        name: 'Time Tracker',
        beginner: true,
        premium: true,
        enterprise: true,
        tooltip: 'See how well a product performs over time',
      },
      // {
      //   name: '678 Billion datapoints',
      //   beginner: true,
      //   premium: true,
      //   enterprise: true,
      //   tooltip: 'See how well a product performs over time',
      // },

    ]
  },
  {
    sectionName: 'Access to',
    features: [
      {
        name: '678 Billion datapoints',
        beginner: true,
        premium: true,
        enterprise: true,
        tooltip: 'Data from over 678 billion datapoints',
      },
      {
        name: '300 Million products',
        beginner: true,
        premium: true,
        enterprise: true,
        tooltip: 'Data from over 300,000,000 products worldwide',
      }
    ]
  },
  {
    sectionName: 'Services',
    features: [
      {
        name: 'Life-time price',
        beginner: true,
        premium: true,
        enterprise: true,
        tooltip: 'Life-time membership for the same fixed price',
      },
      {
        name: 'Weekly updates',
        beginner: false,
        premium: false,
        enterprise: true,
        tooltip: 'Weekly updates on the most popular products',
      }
    ]
  },

  // {
  //   sectionName: 'Quick Look',
  //   features: [
  //     {
  //       name: 'Cancel any time',
  //       beginner: true,
  //       premium: true,
  //       enterprise: true,
  //       tooltip: '',
  //     },
  //     {
  //       name: 'Includes Seats',
  //       beginner: 1,
  //       premium: 1,
  //       enterprise: 6,
  //       tooltip: '',
  //     },
  //   ]
  // },
  // {
  //   sectionName: 'Product Research',
  //   features: [
  //     {
  //       name: 'Niche Finder',
  //       beginner: 50,
  //       premium: 100,
  //       enterprise: 150,
  //       tooltip: 'The best and most effective way to generate product ideas in the blink of an eye.',
  //     },
  //     {
  //       name: 'Niche Rater',
  //       beginner: true,
  //       premium: true,
  //       enterprise: true,
  //       tooltip: '',
  //     },
  //     {
  //       name: 'Sales Spy',
  //       beginner: 50,
  //       premium: 100,
  //       enterprise: 200,
  //       tooltip: '',
  //     },
  //     {
  //       name: 'Love-Hate',
  //       beginner: 5,
  //       premium: 20,
  //       enterprise: 50,
  //       tooltip: '',
  //     },
  //     {
  //       name: 'Easy Source',
  //       beginner: true,
  //       premium: true,
  //       enterprise: true,
  //       tooltip: '',
  //     },
  //   ]
  // },
  // {
  //   sectionName: 'Listing Optimization',
  //   features: [
  //     {
  //       name: 'Keywords on Fire',
  //       beginner: 5,
  //       premium: 20,
  //       enterprise: 40,
  //       tooltip: '',
  //     },
  //     {
  //       name: 'Keywords Spotlight',
  //       beginner: 5,
  //       premium: 10,
  //       enterprise: 20,
  //       tooltip: '',
  //     },
  //     {
  //       name: 'Listing Optimizer',
  //       beginner: 1,
  //       premium: 5,
  //       enterprise: 10,
  //       tooltip: '',
  //     },
  //   ]
  // },
  // {
  //   sectionName: 'Business Metrics',
  //   features: [
  //     {
  //       name: 'Business Dashboard',
  //       beginner: false,
  //       premium: true,
  //       enterprise: true,
  //       tooltip: '',
  //     },
  //     {
  //       name: 'My Products',
  //       beginner: false,
  //       premium: true,
  //       enterprise: true,
  //       tooltip: '',
  //     },
  //     {
  //       name: 'My Orders',
  //       beginner: false,
  //       premium: true,
  //       enterprise: true,
  //       tooltip: '',
  //     },
  //   ]
  // },
  // {
  //   sectionName: 'Monitoring & Alerts',
  //   features: [
  //     {
  //       name: 'Keyword Tracking',
  //       beginner: false,
  //       premium: true,
  //       enterprise: true,
  //       tooltip: '',
  //     },
  //     {
  //       name: 'Product Pulse',
  //       beginner: false,
  //       premium: true,
  //       enterprise: true,
  //       tooltip: '',
  //     },
  //     {
  //       name: 'IP Monitor',
  //       beginner: false,
  //       premium: false,
  //       enterprise: true,
  //       tooltip: '',
  //     },
  //   ]
  // },
  // {
  //   sectionName: 'Customer Engagement',
  //   features: [
  //     {
  //       name: 'E-mail Automator',
  //       beginner: false,
  //       premium: false,
  //       enterprise: true,
  //       tooltip: '',
  //     },
  //     {
  //       name: 'Review Automator',
  //       beginner: false,
  //       premium: false,
  //       enterprise: true,
  //       tooltip: '',
  //     },
  //   ]
  // },
  // {
  //   sectionName: 'Expand',
  //   features: [
  //     {
  //       name: 'Connected Regions',
  //       beginner: true,
  //       premium: true,
  //       enterprise: true,
  //       tooltip: '',
  //     }
  //   ]
  // },
  // {
  //   sectionName: 'Expand',
  //   features: [
  //     {
  //       name: 'Support Priority',
  //       beginner: 'Normal Priority',
  //       premium: 'Normal Priority',
  //       enterprise: 'Normal Priority',
  //       tooltip: '',
  //     }
  //   ]
  // }
];