import makeStyles from "@material-ui/core/styles/makeStyles";

export const usePaymentSelectStyles = makeStyles((theme) => ({
  payment_dropdown_block: {
    width: '100%',
    zIndex: '9999',
    marginTop: '20px',
  },

  payment_dropdown_button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    color: theme.palette.systemColors.grey.dark,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    padding: '11px 16px',
    lineHeight: '25px',
    cursor: 'pointer',
    backgroundColor: theme.palette.systemColors.white.main,
    whiteSpace: 'nowrap',
    position: 'relative',

    border: '1px solid #BEBCBC',
    boxSizing: 'border-box',
    borderRadius: '4px',

    '@media screen and (max-width: 767px)': {
      padding: '9px 10px',
      fontSize: '16px',
      lineHeight: '22px',
      justifyContent: 'start',
    }
  },

  'payment_dropdown_button--active': {
    border: '1px solid #161616',
    boxSizing: 'border-box',
    color: theme.palette.systemColors.text.primary,
    borderRadius: '4px 4px 0 0',
  },

  'payment_dropdown_inner': {
    left: '-1px',
    bottom: '-136px',
    zIndex: '10',
    position: 'absolute',
    width: 'calc(100% + 2px)',
    backgroundColor: theme.palette.systemColors.white.main,
    border: '1px solid #161616',
    borderRadius: '0 0 4px 4px',

  },
  payment_method: {
    padding: '8px 17px',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '25px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.systemColors.text.primary,
    borderBottom: '1px solid #e3e3e3',

    '&:last-child': {
      borderBottom: 'unset',
    },

    '& img': {
      marginRight: '10px',
      height: '28px',
      width: '28px',
    },

    '& span': {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '18px',
      lineHeight: '25px',
    }
  }
}));