import { createMuiTheme } from "@material-ui/core/styles";

export const defaultTheme = createMuiTheme({
  typography: {
    fontFamily: 'Inter, Arial',
    secondary: {
      fontFamily: ' "Bookman Old Style Roman"',
    }
  },
  props: {
    customShadows: {
      soft: '0px 0px 32px rgba(0, 0, 0, 0.1)',
      knob: '0px 2px 4px rgba(0, 0, 0, 0.24)',
    }
  },
  palette: {
    pageBackground: {
      main: '#F2F2F2',
    },
    systemColors: {
      text: {
        primary: '#161616',
      },
      white: {
        main: '#FFFFFF',
      },
      grey: {
        primary: '#BEBCBC',
        dark: '#8E8E8E',
        light: '#E3E3E3',
        ultraLight: '#F5F5F5',
      },
      gold: {
        primary: '#F3AF2C',
        bright: '#F8D042',
      },
      yellow: {
        primary: '#eedb28'
      },
      blue: {
        primary: '#003CB4',
        sidebar: '#0055C1',
        soft: '#99C6FF',
        light: '#CAE1FF',
        ultraLight: '#F8F9FF',
      },
      orange: {
        primary: '#EE8428',
      },
      green: {
        primary: '#65B625',
        light: '#D6F9D0',
      },
      red: {
        primary: '#F84242',
        ultraLight: '#FEE3E3',
      },
      black: {
        primary: "#000",
      }
    }
  }
});