import makeStyles from "@material-ui/core/styles/makeStyles";

export const usePaymentFormStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    marginTop: '20px',
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  sepaConfirm: {
    ...theme.typography.fontFamily.secondary,
    fontSize: '12px',
  },

  inputWrapper: {
    paddingTop: '0',
    paddingBottom: '0',
    minWidth: '147px',
  },

  nameWrapper: {
    marginTop: '22px',
    paddingTop: '0',
    paddingBottom: '0',
    minWidth: '147px',
  },

  legendWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
  },

  legend: {
    fontSize: '18px',
    lineHeight: '25px',
    fontWeight: 'bold',
    color: theme.palette.systemColors.text.primary,
  },

  inputError: {
    minWidth: '160px',
    position: 'absolute',
    color: theme.palette.systemColors.red.primary,
    fontSize: '14px',
    lineHeight: '19px',
  },

  idealInputError: {
    minWidth: '160px',
    color: theme.palette.systemColors.red.primary,
    fontSize: '14px',
    lineHeight: '19px',
  },


  stripeElementBase: {
    padding: '8px 16px 8px 13px',
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '25px',
    background: theme.palette.systemColors.white.main,
    border: '1px solid #BEBCBC',
    boxSizing: 'border-box',
    borderRadius: '4px',
  },

  stripeElementInvalid: {
    color: theme.palette.systemColors.text.primary,
    borderColor: theme.palette.systemColors.red.primary,
  },

  stripeElementFocus: {
    borderColor: theme.palette.systemColors.text.primary,
    color: theme.palette.systemColors.text.primary,
  },

  stripeElementWrapperRelative: {
    position: 'relative',
  },

  inputAdornment: {
    position: 'absolute',
    top: '2px',
    right: '30px',
    transform: 'translateY(50%)',
    height: '20px',
  }
}));