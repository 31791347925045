export const UPDATE_ACCOUNT_INFO_FETCH = '@profile/UPDATE_ACCOUNT_INF_FETCH';
export const UPDATE_ACCOUNT_INFO_SUCCESS = '@profile/UPDATE_ACCOUNT_INFO_SUCCESS';
export const UPDATE_ACCOUNT_INFO_ERROR = '@profile/UPDATE_ACCOUNT_INFO_ERROR';
export const CLEAR_ERROR_SUCCESS_MESSAGES = '@profile/CLEAR_ERROR_SUCCESS_MESSAGES';
export const RESEND_EMAIL = '@profile/RESEND_EMAIL';
export const RESEND_EMAIL_SUCCESS = '@profile/RESEND_EMAIL_SUCCESS';
export const RESEND_EMAIL_ERROR = '@profile/RESEND_EMAIL_ERROR';
export const DISABLE_DONE_FLAG = '@profile/DISABLE_DONE_FLAG';

export const CREATE_SUBSCRIPTION_FETCH = '@profile/CREATE_SUBSCRIPTION_FETCH';
export const CREATE_SUBSCRIPTION_SUCCESS = '@profile/CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_ERROR = '@profile/CREATE_SUBSCRIPTION_ERROR';

export const UPDATE_DEFAULT_PAYMENT_METHOD_FETCH = '@profile/UPDATE_DEFAULT_PAYMENT_METHOD_FETCH';
export const UPDATE_DEFAULT_PAYMENT_METHOD_SUCCESS = '@profile/UPDATE_DEFAULT_PAYMENT_METHOD_SUCCESS';
export const UPDATE_DEFAULT_PAYMENT_METHOD_ERROR = '@profile/UPDATE_DEFAULT_PAYMENT_METHOD_ERROR';

export const DEFAULT_PAYMENT_METHOD_FETCH = '@profile/DEFAULT_PAYMENT_METHOD_FETCH';
export const DEFAULT_PAYMENT_METHOD_SUCCESS = '@profile/DEFAULT_PAYMENT_METHOD_SUCCESS';
export const DEFAULT_PAYMENT_METHOD_ERROR = '@profile/DEFAULT_PAYMENT_METHOD_ERROR';


export const UPDATE_SUBSCRIPTION_FETCH = '@profile/UPDATE_SUBSCRIPTION_FETCH';
export const UPDATE_SUBSCRIPTION_SUCCESS = '@profile/UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION_ERROR = '@profile/UPDATE_SUBSCRIPTION_ERROR';

