import React, {useState, useEffect} from 'react';
import {PAYMENT_METHODS_LIST} from "../../constants/paymentMethods";
import {useTranslation} from "react-i18next";
import {usePaymentSelectStyles} from "./styles/paymentSelectStyles";

const PaymentMethodSelect = ({selectedMethod, setSelectedMethod = () => ({})}) => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dropListRef = React.useRef(null);
  const styles = usePaymentSelectStyles();

  const openDropdownHandler = () => {
    setIsOpen(!isOpen);
  };

  const handlePaymentMethodChange = (type) => () => {
    setSelectedMethod(type);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    function handleClickOutside(e) {
      if(dropListRef.current && !dropListRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [dropListRef, setIsOpen]);

  const buttonClassName = [styles.payment_dropdown_button, isOpen && styles['payment_dropdown_button--active']];

  return (
    <div className={styles.payment_dropdown_block}>
      <button className={buttonClassName.join(' ')} onClick={openDropdownHandler}>
        <span>{PAYMENT_METHODS_LIST[selectedMethod]?.name ? t(PAYMENT_METHODS_LIST[selectedMethod].name) : t('Select payment method')}</span>
        <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M0.43934 0.43934C1.02513 -0.146447 1.97487 -0.146447 2.56066 0.43934L10.5 8.37868L18.4393 0.43934C19.0251 -0.146447 19.9749 -0.146447 20.5607 0.43934C21.1464 1.02513 21.1464 1.97487 20.5607 2.56066L11.5607 11.5607C10.9749 12.1464 10.0251 12.1464 9.43934 11.5607L0.43934 2.56066C-0.146447 1.97487 -0.146447 1.02513 0.43934 0.43934Z" fill="#8E8E8E"/>
        </svg>
        {isOpen &&
        <div className={styles.payment_dropdown_inner} ref={dropListRef}>
          {Object.values(PAYMENT_METHODS_LIST).map((method, index) => {
            return (
              <div key={index} onClick={handlePaymentMethodChange(method.type)} className={styles.payment_method}>
                <img src={`/images/payment-methods/${method.imageName}`} alt=""/>
                <span>{t(method.name)}</span>
              </div>
            )
          })}
        </div>
        }
      </button>
    </div>
  );
};

export default PaymentMethodSelect;