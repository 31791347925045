export default {
  "Already cancel still active": "You've already cancel subscription. Your account is still active until the end of this period",
  //PayPalReturn
  'Getting subscription data success': 'Getting subscription data: success',
  'Waiting for subscription status...': 'Waiting for subscription status...',

  //PaymentMethods
  'Credit Card': 'Credit Card',
  'Select payment method': 'Select payment method',

  //IdealReturn
  // "You are starting free trial": "You are starting free trial",
  // "Success! Redirecting to your account.":"Success! Redirecting to your account."
  "Setup intent status": "Setup intent status: {{status}}",
  "Choose a payment method": "Choose a payment method",
  "Your 14-day trial is completely FREE and you will not be charged until . You can switch to a different plan or cancel anytime.": "Your 14-day trial is completely FREE and you will not be charged until {{endTime}}. You can switch to a different plan or cancel anytime.",
  'Bank authentication success': 'Bank authentication: success',
  'Error': 'Error',
  'Waiting for subscription creation ...': 'Waiting for subscription creation ...',
  // 'Subscription error': 'Subscription error: {{error}}',

  //LoginRegistrationForm
  'Wrong redirect url, please contact administrator': 'Wrong redirect url, please contact administrator',
  'E-mail': 'E-mail',
  // 'Enter your email': 'Enter your email',
  "Remember me": "Remember me",
  'Forgot password': 'Forgot password',
  'Login': 'Login',
  'Send': 'Send',
  'Create profile': 'Create profile',
  "Don't have an account?": "Don't have an account?",
  'Already have an account?': 'Already have an account?',
  'Register': 'Register',
  // 'Login': 'Login',

  // 'E-mail is a required field': 'E-mail is a required field',


  //SideBar
  'Upgrade to': 'Upgrade to',
  // 'Enterprise': 'Enterprise',

  'To expand your': 'To expand your',
  'eCommerce business': 'eCommerce business',

  "Time to upgrade": "Time to upgrade",
  "to enterprise": "to enterprise",

  'Try now': 'Try now',

  /*******PlansTable*********/

  'Count of searches on niche finder per month': 'Count of searches on niche finder per month',


  //plansHeader
  'Bill monthly': 'Bill monthly',
  "Bill annually": "Bill annually",

  //plan features

  'Extras': 'Extras',
  'E-pickr community': 'E-pickr community',
  'Access to the e-pickr community': 'Access to the e-pickr community',
  'Expert’ support': 'Expert’ support',
  'Instant support from our experts': 'Instant support from our experts',


  'Tools': 'Tools',
  // 'Niche Finder':'Niche Finder',
  'Count of searches on niche pickr per month': 'Count of searches on niche pickr per month',
  'Sales Pickr': 'Sales Pickr',
  'Sales pickr': 'Sales pickr',
  'Count of stored products for historical performance data': 'Count of stored products for historical performance data',
  'Niche Rater': 'Niche Rater',
  'Up-to-date data and scores for potential niches': 'Up-to-date data and scores for potential niches',
  'Time Tracker': 'Time Tracker',
  'See how well a product performs over time': 'See how well a product performs over time',
  'Access to': 'Access to',
  '678 Billion datapoints': '678 Billion datapoints',
  'Data from over 678 billion datapoints': 'Data from over 678 billion datapoints',
  '300 Million products': '300 Million products',
  'Data from over 300,000,000 products worldwide': 'Data from over 300,000,000 products worldwide',
  'Services': 'Services',
  'Life-time price': 'Life-time price',
  'Life-time membership for the same fixed price': 'Life-time membership for the same fixed price',
  'Weekly updates': 'Weekly updates',
  'Weekly updates on the most popular products': 'Weekly updates on the most popular products',


  'Error while setting user plan, please try again': 'Error while setting user plan, please try again',
  "Refund error for subscription": "Error while do refund for subscription id: {{subscriptionId}}. Error: {{message}}, please refer to administrator with subscription id to solve refund problem",
  // 'Please confirm the payment': 'Please confirm the payment',
  // "Confirmation error": "Confirmation error {{error}}",
  // 'Unknown Subscription status': 'Unknown Subscription status: {{status}}',

  "Updating error": "Updating error! {{error}}",
  "Are you sure you want live this page ?": "Are you sure you want live this page ?",

  "Try for free": "Try for free",

  'Current plan': 'Current plan',
  'Choose this plan': 'Choose this plan',
  'Select Plan': 'Select Plan',

  'month': 'month',
  'year': 'year',




  /*******PlansTable*********/



  /********Settings**********/
  //ContentBlockHeaderTitle
  'My profile': 'My profile',
  'Subscription': 'Subscription',


  //Profile SidebarItems
  'Product finder': 'Product finder',
  'Niche pickr': 'Niche pickr',
  'Sales pick': 'Sales pick',
  'Settings': 'Settings',
  'Profile': 'Profile',
  // 'Subscription': 'Subscription',
  'What are you looking for?': 'What are you looking for?',
  'Payment info': 'Payment info',
  'My account': 'My account',
  'Sign out': 'Sign out',

  //Subscription SidebarItems
  "Cancellation error": "Cancellation error: {{error}}",
  'My current plan': 'My current plan',
  'Next payment': 'Next payment:',
  'Ends at': 'Ends at:',
  'Change my plan': 'Change my plan',
  'Cancel my subscription': 'Cancel my subscription',

  //CancelSubscriptionDialog

  //next 3 is one phrase
  'You are about to': 'You are about to',
  'cancel': 'cancel',
  'your subscription. Do you wish to proceed?': 'your subscription. Do you wish to proceed?',


  'Yes, proceed': 'Yes, proceed',
  // 'Cancel': 'Cancel',


  //PaymentDetails
  'Pay online via': 'Pay online via:',
  "Ends on": 'Ends on:',
  "Expires": 'Expires',
  "Signed in as": "Signed in as:",
  "Edit": "Edit",
  "select a different payment method": "select a different payment method",


  //Ideal
  "By providing your payment information and confirming this payment, you authorise (A) COMPANY_NAME and Stripe, our payment service provider and/or PPRO, its local service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.": "By providing your payment information and confirming this payment, you authorise (A) COMPANY_NAME and Stripe, our payment service provider and/or PPRO, its local service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.",
  "Confirm": "Confirm",

  //Stripe
  'Please enter a valid card number': 'Please enter a valid card number',
  'Please enter a valid date': 'Please enter a valid date',
  'Please enter a valid code': 'Please enter a valid code',

  "Subscription error": "Subscription error: {{error}}",
  "You are starting free trial": "You are starting free trial",
  "Success! Redirecting to your account.": "Success! Redirecting to your account.",
  "Please confirm the payment.": "Please confirm the payment.",
  "Confirmation error": "Confirmation error: {{error}}",
  'Unknown Subscription status': 'Unknown Subscription status: {{status}}',
  'Creating payment method error': 'Creating payment method error: {{error}}',
  'Card number': 'Card number',
  'Expiration data': 'Expiration data',
  'CVC/CID code': 'CVC/CID code',
  "Name on the card": "Name on the card",


  //PayPal
  'Update paypal payment method error': 'Update paypal payment method error: {{error}}',
  'Create paypal subscription error': 'Create paypal subscription error: {{error}}',

  //UpdateAccountInfoDialog
  'Your account field has successfully been changed': 'Your {{fieldName}} has successfully been changed.',
  'e-mail address': 'e-mail address',
  'password': 'password',
  'name': 'name',
  'account info': 'account info',

  //UpdateInfoForm
  'Name': 'Name',
  'enter your name': 'enter your name',
  'E - mail address': 'E - mail address',
  "Enter your email": "Enter your email",
  "We have sent a verification link to this e-mail address.": "We have sent a verification link to this e-mail address.",
  "Send again": "Send again",
  "Change your password": "Change your password",
  "Old password": "Old password",
  'Enter your old password': 'Enter your old password',
  "Password": "Password",
  'Enter your password': 'Enter your password',
  'Should be min 8 char and max 50 char long having at least 1 number, 1 uppercase, 1 lowercase character.': 'Should be min 8 char and max 50 char long having at least 1 number, 1 uppercase, 1 lowercase character.',
  "Save": "Save",
  "Saved": "Saved",
  // "Cancel": "Cancel",
  // "Edit": "Edit",

  //editProfileSchema
  'Name is a required field': 'Name is a required field',
  'Max length is 256 charts': 'Max length is 256 charts',
  'E-mail is a required field': 'E-mail is a required field',
  'Please enter a valid email address': 'Please enter a valid email address',
  'Min length is 6 charts': 'Min length is 6 charts',
  'Enter valid Old password': 'Enter valid Old password',
  'Should be at least 8 charts': 'Should be at least 8 charts',
  'Max length is 50 charts': 'Max length is 50 charts',
  'Old Password is a required field': 'Old Password is a required field',
  'Password should contain letters, numbers and special symbols': 'Password should contain letters, numbers and special symbols',
  'Password is a required field': 'Password is a required field',

  //Subscription
  'Your subscription plan has successfully been changed.': 'Your subscription plan has successfully been changed.',
  'searches this month': 'searches this month',
  'products tracked': 'products tracked',
  'You can get one time month refund if complete survey': 'You can get one time month refund if complete survey',
  'Take a survey': 'Take a survey',
  "Refund is in processing now": "Refund is in processing now, it can takes up to 30 days depends on our payment method. If it takes more time please contact to administrator.",
  //UpdatePlanDialog
  //next 2 lines is one phrase
  "You are about to change your subscription plan to": "You are about to change your subscription plan to",
  ". Do you wish to proceed?": ". Do you wish to proceed?",
  "Do you want go to plans page?": "Do you want go to plans page?",

  //'Yes, proceed': 'Yes, proceed',
  //"Cancel": "Cancel",

  //plan types
  "Beginner": 'Beginner',
  "Premium": "Premium",

  //plan duration
  "Enterprise": "Enterprise",

  'This is your current subscription plan, changes made to this will become visible from the beginning of next billing period onwards.': 'This is your current subscription plan, changes made to this will become visible from the beginning of next billing period onwards.',




  /********Settings**********/

  /**********SalesSpy*********/
  "Don't know where to start": "Don't know where to start",
  'Fill in at least one ASIN number to start adding your product': 'Fill in at least one ASIN number to start adding your product',
  'reviews': 'reviews',

  //durationSelectCheckboxShape
  'Added date': 'Added date',
  '7 days ago': '7 days ago',
  '30 days ago': '30 days ago',
  '3 months ago': '3 months ago',
  '6 months ago': '6 months ago',
  '1 year ago': '1 year ago',

  //RemoveSalesSpyDialog
  "Are you sure you want to delete this from Sales Spy?": "Are you sure you want to delete this from Sales Spy?",
  "You’re about to remove this product:": "You’re about to remove this product:",
  'Remove product': 'Remove product',
  //"Cancel": "Cancel",

  /**********SalesSpy*********/


  /**********Add-ons**********/

  //RouteLeave
  'Be careful!': 'Be careful!',
  'If you have unsaved changes, then save them first before leaving this page.': 'If you have unsaved changes, then save them first before leaving this page.',
  'Saved pages will be loaded again when you open it again, or open another tab if you cannot save the changes.': 'Saved pages will be loaded again when you open it again, or open another tab if you cannot save the changes.',
  'Leave anyways': 'Leave anyways',
  'Open another tab': 'Open another tab',
  'Stay on this page': 'Stay on this page',

  /**********NicheFinder*********/

  //ContentBlockHeaderTitle
  'Niche Pickr': 'Niche Pickr',
  'out of searches used': '{{searchesCount}} out of {{total}} searches used',
  'out of products tracked': '{{productCount}} out of {{total}} products tracked',

  //hiche finder filter shape
  // 'Buyer Demand': 'Buyer Demand',
  'Buyer demand is calculated with the use of various data points how likely people are to buy products within the suggested niche. We base this number on monthly sales in combination with search trends from customers for the keyword.': 'Buyer demand is calculated with the use of various data points how likely people are to buy products within the suggested niche. We base this number on monthly sales in combination with search trends from customers for the keyword.',
  // 'Niche Score': 'Niche Score',
  'Niche score is the final result from a weighted average of the four pillars we use to define what a great product for ecommerce is.': 'Niche score is the final result from a weighted average of the four pillars we use to define what a great product for ecommerce is.',
  // 'Competitor Check': 'Competitor Check',
  'Competition check takes the strength and sales from competitors into account. How likely can you sell a product in this niche. If other sellers are having great optimized listings, then the chance to sell for a new enterer becomes harder, we mitigate this risk by looking at the probability to create a better listing that the incumbent/ existing listings.': 'Competition opportunity takes the strength and sales from competitors into account. How likely can you sell a product in this niche. If other sellers are having great optimized listings, then the chance to sell for a new enterer becomes harder, we mitigate this risk by looking at the probability to create a better listing that the incumbent/ existing listings.',
  'Average Listing Strength': 'Average Listing Strength',
  'The strength of a listing is dependent on numerous factors of a listing. For example, the optimization of keywords, EBC, images and reviews and review scores. The better this score, the more likely a listing is to convert into sales.': 'The strength of a listing is dependent on numerous factors of a listing. For example, the optimization of keywords, EBC, images and reviews and review scores. The better this score, the more likely a listing is to convert into sales.',
  'Financial Fit': 'Financial Fit',
  'Financial fit is calculated to see what amount of inventory levels and the associated costs a new seller should consider to have a relative market share that corresponds to the return on investment (ROI). This number is adjustable with the use of the filters below for more risk seeking sellers or safe players. The reality shows that some products sell at way higher pace but have a lower ROI; or on the contrary they have a very high ROI but only sell a little per month. This requires different inventory levels. Dropshippers can also select this filter to be 0, since inventory is not relevant to them, although there remain valuable insights in this measure.': 'Financial fit is calculated to see what amount of inventory levels and the associated costs a new seller should consider to have a relative market share that corresponds to the return on investment (ROI). This number is adjustable with the use of the filters below for more risk seeking sellers or safe players. The reality shows that some products sell at way higher pace but have a lower ROI; or on the contrary they have a very high ROI but only sell a little per month. This requires different inventory levels. Dropshippers can also select this filter to be 0, since inventory is not relevant to them, although there remain valuable insights in this measure.',
  // 'Revenue Potential': 'Revenue Potential',
  'The essence of revenue potential is to show you an estimate of profit that a seller can make within this niche. We take the sale pace and price into account and calculate the average sales revenue for a listing.': 'The essence of revenue potential is to show you an estimate of profit that a seller can make within this niche. We take the sale pace and price into account and calculate the average sales revenue for a listing.',
  'Search Volume': 'Search Volume',
  'Estimated number of exact match Amazon searches for the suggested keyword in this niche.': 'Estimated number of exact match Amazon searches for the suggested keyword in this niche.',
  'Launch Budget': 'Launch Budget',
  'The launch budget is an adjustable measure where you can mitigate your risk. If you want to look for big hitters or a small starter, here you can filter your strategy.': 'The launch budget is an adjustable measure where you can mitigate your risk. If you want to look for big hitters or a small starter, here you can filter your strategy.',
  'Average Revenue': 'Average Revenue',
  'Average gross revenue (before tax, commissions and shipping costs) per month for the specified niche keyword.': 'Average gross revenue (before tax, commissions and shipping costs) per month for the specified niche keyword.',
  'Average Sales': 'Average Sales',
  'The average number of products sold per month for the related listings in this niche.': 'The average number of products sold per month for the related listings in this niche.',
  'Average Price': 'Average Price',
  'The average price of products for the listings suggested by the keyword.': 'The average price of products for the listings suggested by the keyword.',
  'Average Rating': 'Average Rating',
  'The average number of ratings a keyword has which is used in the listing strength calculation. The more ratings and the higher the rating itself, the more competition on this listing.': 'The average number of ratings a keyword has which is used in the listing strength calculation. The more ratings and the higher the rating itself, the more competition on this listing.',

  //SEND MAIL
  "Have a question? Send email to us": "Have a question? Send email to us",
  "Ask us": "Ask us",

  //NicheFinderFilter
  "Min": "Min.",
  "Max": "Max.",
  'optional': '(optional)',
  // 'Filter results': 'Filter results',
  "Reset filters": "Reset filters",
  "Apply filters": "Apply filters",

  //InputButtonGroup
  "Please enter correct asin": "Please enter correct asin",
  "Let's roll": "Let's roll",
  "Add product": "Add product",
  "Enter keyword": "Enter keyword",
  "Enter ASIN number": "Enter ASIN number",
  'Filter results': 'Filter results',


  //MultiSelectCheckbox
  'Select category': 'Select category',
  "All Categories": "All Categories",
  "Categories": "Categories {{count}}",
  'Select categories': 'Select categories',
  "Select all": "Select all",
  'Reset': 'Reset',
  'Confirm selection': 'Confirm selection',

  //NicheFinder
  "Keywords": "Keywords",
  "Niche Score": "Niche Score",
  "Buyer Demand": "Buyer Demand",
  "Competitor Check": "Competitor Check",
  "Profit Potential": "Profit Potential",


  //NicheFinderTable
  "Let's find your treasure": "Let's find your treasure",
  "You should fill in at least 1 keyword to start searching": "You should fill in at least 1 keyword to start searching",
  'unknown': 'unknown',

  //NicheFinderDetails
  "Product details": "Product details",
  "Based on": "Based on {{totalReviews}} reviews",
  "LS": "LS",
  "Sales": "Sales",


  //Message
  "successfully sent": "Successfully sent for you",
  "is sent": "is sent to your Salespickr",
  "Successfully added": "Successfully added",
  "is added": "is added to your Salespickr",
  "Successfully deleted": "Successfully deleted",
  "is deleted": "is deleted from your Salespickr",


  //AddSalesSpyDialog
  "Time to send it to Sales pick": "Time to sent it to Sales pick. Are you sure?",
  "out of used": "{{salesSpyProductsCount}} out of {{totalSpyProductsCount}} sent(s) used",
  "You’re about to sent the following product": "You’re about to sent the following product:",
  "Send result": "Send result",
  "Cancel": "Cancel",

  /**********NicheFinder*********/

  /**********Calculator*********/
  // ConfirmResetCalculatorValuesDialog
  "Reset": "Reset",
  "Do you want to change the currency?": "Do you want to change the currency?",
  "Are you sure you want to reset the calculatr?": "Are you sure you want to reset the calculatr?",
  "This action will reset all fields of the calculatr": "This action will reset all fields of the calculatr",

  // Validation
  "Max 2 decimal places allowed": "Max 2 decimal places allowed",
  "Max value for this field is 9999": "Max value for this field is 9999",
  "Min value in this field should be 1": "Min value in this field should be 1",
  "Invalid number": "Invalid number",

  // Acquisition
  "Acquisition costs (Excl VAT)": "Acquisition costs (Excl VAT)",
  "Total": "Total",
  /**********Calculator*********/
}