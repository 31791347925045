import { AUTH_TYPES, KEYWORDS_TYPES, SALES_SPY_TYPES, PROFILE_TYPES, SALESESTIMATOR_TYPES, ALIBABA_TYPES } from "../types";
import { reloadToken, removeToken } from "../../utils/agent";

const initState = {
  loading: false,
  done: false,
  isInitialized: false,
  error: false,
  errorMessage: '',
  //errorObj for forms only
  errorObj: null,
  successMessage: '',
  user: null,
  token: null,
};

const authReducer = (state = initState, { type, payload, message, errObj }) => {
  switch (type) {
    case AUTH_TYPES.AUTH_FETCH: {
      return {
        ...state, loading: true
      }
    }

    case AUTH_TYPES.AUTH_SET_INITIALIZED: {
      return {
        ...state, isInitialized: true,
      }
    }

    case PROFILE_TYPES.UPDATE_DEFAULT_PAYMENT_METHOD_SUCCESS: {
      if (payload.subscription) {
        return {
          ...state, user: {
            ...state.user, subscription: payload.subscription
          }
        }
      }
      return state;
    }

    case PROFILE_TYPES.CREATE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state, user: { ...state.user, subscription: payload.subscription }
      }
    }

    case PROFILE_TYPES.CREATE_SUBSCRIPTION_ERROR: {
      return {
        ...state, user: { ...state.user, subscription: { error: message } }
      }
    }

    case KEYWORDS_TYPES.FETCH_KEYWORDS_SUCCESS: {
      return {
        ...state, user: { ...state.user, nicheSearchesCount: Number(payload.searchesLeft) }
      }
    }

    case SALES_SPY_TYPES.ADD_PRODUCT_SUCCESS: {
      return {
        ...state, user: { ...state.user, salesSpyProductsCount: Number(state.user.salesSpyProductsCount) + 1 }
      }
    }

    case SALES_SPY_TYPES.DELETE_PRODUCT_SUCCESS: {
      return {
        ...state, user: { ...state.user, salesSpyProductsCount: Number(state.user.salesSpyProductsCount) - 1 }
      }
    }

    case SALESESTIMATOR_TYPES.SEARCH_PRODUCTS_SUCCESS: {
      return {
        ...state, user: { ...state.user, salesEstimatrCount: Number(payload.salesEstimatrCount) }
      }
    }

    case ALIBABA_TYPES.SEARCH_PRODUCTS_SUCCESS: {
      return {
        ...state, user: { ...state.user, alibabaSearchesCount: Number(payload.searchesLeft) }
      }
    }

    case PROFILE_TYPES.UPDATE_ACCOUNT_INFO_SUCCESS: {
      return {
        ...state, user: { ...payload.updated }
      }
    }

    case AUTH_TYPES.AUTH_RECOVER_SUCCESS: {
      const successMessage = payload.message;
      return {
        ...state, done: true, loading: false, error: false, errorMessage: '', errorObj: null, successMessage,
      }
    }

    case AUTH_TYPES.SET_USER: {
      return {
        ...state, user: payload.user, token: payload.token
      }
    }

    case AUTH_TYPES.AUTH_SUCCESS: {
      const successMessage = message;
      const { user, token } = payload;
      localStorage.setItem('token', token);
      reloadToken(token);
      return { ...state, isInitialized: true, done: true, loading: false, error: false, errorMessage: '', successMessage, user, token, errorObj: null }
    }

    case AUTH_TYPES.AUTH_CREATE_SUCCESS: {
      const successMessage = payload.message;
      return { ...state, isInitialized: true, done: true, loading: false, error: false, errorMessage: '', successMessage, user: null, errorObj: null }
    }


    case AUTH_TYPES.CLEAR_ERROR_SUCCESS_MESSAGES: {
      return { ...state, error: false, errorMessage: '', errorObj: null, successMessage: '', done: false }
    }

    case AUTH_TYPES.AUTH_REFRESH_ERROR:
    case AUTH_TYPES.AUTH_ERROR: {
      const errorMessage = message;
      const errorObj = errObj;
      return { ...state, isInitialized: true, done: true, loading: false, error: true, errorMessage, successMessage: '', user: null, errorObj }
    }
    case AUTH_TYPES.AUTH_CLEAR:
      removeToken();
      localStorage.removeItem("userLastActivity");
      window.location.reload();
      return { ...initState };
    default:
      return state;
  }
}

export default authReducer;