import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import {useSelector} from "react-redux";

const AuthRoute = ({ component: Component, componentProps, ...rest }) => {
  const user = useSelector(state => state.authReducer.user);
  const location = useLocation();

  if(user) {
    return <Route {...rest}><Component {...componentProps}/></Route>
  }

  return <Redirect to={{pathname: '/login', state: {from: location}}} />
};

export default AuthRoute;