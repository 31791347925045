import { AUTH_CLEAR, AUTH_REFRESH_ERROR, AUTH_ERROR } from '../types/auth';
import {refreshAuth} from '../actions/auth'

function unAuthorizedMiddleware() {
  return function(_ref) {
    const dispatch = _ref.dispatch;
    //getState = _ref.getState;
    return function(next) {
      return function(action) {
        if (action.type === AUTH_REFRESH_ERROR) {
          dispatch({ type: AUTH_CLEAR });
          window.location.reload();
          return;
        }
        if (action.message === 'Unauthorized' || action.type === AUTH_ERROR) {
          if(localStorage.getItem('token')){
            return dispatch(refreshAuth());
          } 
        }
        return next(action);
      };
    };
  };
}

export default unAuthorizedMiddleware();