import { ALIBABA_TYPES } from "../types";

const initState = {
  loading: false,
  products: [],
  page: 1,
  pageSize: 10,
  totalCount: 0,
  order: "desc",
  orderBy: "",
  productTitle: "",
  addProduct: false,
  filterIsOpen: false,
  detailsIsOpen: false,
  filterSettings: null,
  itemDetails: null,
  searchKeyword: null,
};

const AlibabaReducer = (state = initState, { type, payload, reqData }) => {
  switch (type) {
    case ALIBABA_TYPES.SET_ALIBABA_TABLE_PARAMS: {
      const { order, orderBy } = payload;
      return {
        ...state,
        order,
        orderBy,
      };
    }
    case ALIBABA_TYPES.SET_ALIBABA_TABLE_FILTER: {
      return { ...state, filterSettings: payload };
    }
    case ALIBABA_TYPES.RESET_ALIBABA_TABLE_FILTER: {
      return { ...state, filterSettings: initState.filterSettings };
    }
    case ALIBABA_TYPES.OPEN_ALIBABA_FILTER: {
      return { ...state, filterIsOpen: true };
    }
    case ALIBABA_TYPES.CLOSE_ALIBABA_FILTER: {
      return { ...state, filterIsOpen: false };
    }
    case ALIBABA_TYPES.OPEN_ALIBABA_DETAILS: {
      return { ...state, detailsIsOpen: true };
    }
    case ALIBABA_TYPES.CLOSE_ALIBABA_DETAILS: {
      return { ...state, detailsIsOpen: false };
    }
    case ALIBABA_TYPES.SEARCH_PRODUCTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case ALIBABA_TYPES.SEARCH_PRODUCTS_SUCCESS: {
      const { total, page, pageSize } = payload.result;
      return {
        ...state,
        products: payload.result.products,
        totalCount: total,
        page,
        pageSize,
        searchKeyword: reqData.meta.query,
        loading: false,
      };
    }
    case ALIBABA_TYPES.SEARCH_ALIBABA_PRODUCTS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case ALIBABA_TYPES.FETCH_SINGLE_ITEM_DETAILS_SUCCESS: {
      return {
        ...state,
        itemDetails: { ...payload, ...reqData.meta.item },
        detailsIsOpen: true,
      };
    }
    default:
      return state;
  }
};

export default AlibabaReducer;
