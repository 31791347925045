import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import {
  makeStyles,
  Container
} from '@material-ui/core';
import {NavLink, useHistory} from "react-router-dom";
import Ideal from "./Ideal";
import PayPal from "./PayPal";
import StripeCard from "./StripeCard";
import {STRIPE_CARD, IDEAL, PAYPAL, STATUS_CANCELLED} from "../../constants/paymentMethods";
import PaymentMethodSelect from "./PaymentMethodSelect";
import {useTranslation} from "react-i18next";
import PlanOverview from "../LoginRegistrationForm/PlanOverview";
import { PRODUCT_FINDER_ROUTES } from '../../constants/routes';


const useStyles = makeStyles((theme) => ({
  fixed_left_link: {
    position: 'fixed',
    top: '25px',
    left: '25px',
    fontWeight: 400,
    textDecoration: 'underline',

    '& a': {
      color: theme.palette.systemColors.blue.primary
    },

    '& a:visited': {
      color: theme.palette.systemColors.blue.primary
    }
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: theme.palette.pageBackground.main
  },
  container: {
    padding: 0,
    display: 'flex',
    maxWidth: '406px',
    backgroundColor: 'white',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '0px 0px 8px 8px',
    marginLeft: '20px',
  },
  header: {
    backgroundColor: theme.palette.systemColors.blue.primary,
    borderRadius: '8px 8px 0px 0px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    color: 'white',
    alignItems: 'center',
    padding: '24px 40px 24px 40px'
  },
  logo: {
    marginTop: '24px'
  },
  title: {
    margin: 0,
    fontSize: '24px',
    lineHeight: '24px',
    marginBottom:'4px',
    textAlign: 'center',
    fontWeight: 800,
  },
  inputWrapper: {
    paddingTop: 0,
    paddingBottom: 0
  },
  subTitle: {
    ...theme.typography.fontFamily.secondary,
    color: theme.palette.systemColors.text.primary,
    padding: '0 40px 20px 40px',
    fontSize: '16px',
    textAlign: 'center',
    lineHeight: '20px',
  },
  formContainer: {
    minHeight: '405px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '24px 40px 20px 40px',
  },
}))


const PaymentMethods = {
  [IDEAL]: <Ideal/>,
  [PAYPAL]: <PayPal/>,
  [STRIPE_CARD]: <StripeCard />,
}

export default function ChoosePaymentMethod() {
  const {t} = useTranslation();
  const history = useHistory();
  const status = useSelector(state => state.authReducer?.user.subscription?.status);
  const isTrial = useSelector(state => state.authReducer?.user.subscription?.isTrial);
  const isTreeMonthOffer = useSelector(state => state.authReducer?.user.subscription?.isTreeMonthOffer);
  const isStripeResponse = useSelector(state => state.authReducer?.user.subscription?.isStripeResponse);
  const defaultPaymentSystem = useSelector(state => state.authReducer?.user.subscription?.defaultPaymentSystem);
  const billPeriod = useSelector(state => state.registerPlanReducer.billPeriod);
  const currentPlan = useSelector(state => state.authReducer?.user?.plan);
  const subscriptionBillPeriod = useSelector(state => state.authReducer?.user?.subscription.billingPeriod);
  const period = billPeriod || subscriptionBillPeriod;
  const isCanceledSubscription = !defaultPaymentSystem && STATUS_CANCELLED === status;

  const classes = useStyles();
  const [selectedMethod, setSelectedMethod] = useState('');

  const getTrialEndDate = () => {
    const today = new Date();
    return new Date(today.setDate(today.getDate() + 14)).toLocaleDateString().replaceAll(".", "-");
  }

  useEffect(()=> {
    if(!isStripeResponse && (!currentPlan || !period)) {
      history.push('/settings/subscription?show_modal=true');
    }
  }, [period, currentPlan, isStripeResponse]);

  useEffect(()=> {
    !isCanceledSubscription && status && !isStripeResponse && history.replace(PRODUCT_FINDER_ROUTES.NICHE_PICKR);
  }, [isCanceledSubscription, status, isStripeResponse]);

  return (
    <>
      {isCanceledSubscription &&
        <div className={classes.fixed_left_link}>
          <NavLink to={'/settings/subscription'}>
            {t("Change Plan")}
          </NavLink>
        </div>
      }
      <div className={classes.wrapper}>
        <PlanOverview />
        <Container component="main" maxWidth="xs" className={classes.container}>
          <div className={classes.header}>
            <p className={classes.title}>
              {t('Choose a payment method')}
            </p>
          </div>
          <div className={classes.formContainer}>
            <PaymentMethodSelect setSelectedMethod={setSelectedMethod} selectedMethod={selectedMethod}/>
            {PaymentMethods[selectedMethod] || <></>}
          </div>
          {isTrial && !isTreeMonthOffer &&
            <p className={classes.subTitle}>
              {t("Your 14-day trial is completely FREE and you will not be charged until . You can switch to a different plan or cancel anytime.", {endTime: getTrialEndDate()})}
            </p>
          }
          {isTrial && isTreeMonthOffer &&
          <p className={classes.subTitle}>
            {t("Good choice, the best price for the best tools! We are sure you will love it!")}
          </p>
          }
        </Container>
      </div>
    </>
  )
}