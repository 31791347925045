import {PROFILE_TYPES, REGISTER_PLAN_TYPES} from "../types";
import {AUTH_TYPES} from "../types";
import {MONTHLY} from "../../constants/PlansFeatures";

const initState = {
  // loading: false,
  // done: false,
  // error: false,
  // errorMessage: '',
  //errorObj for forms only
  // errorObj: null,
  // successMessage: '',
  billPeriod: /*localStorage.getItem('billPeriod') ||*/ MONTHLY,
  planName: ""/*localStorage.getItem('planName')*/,
  isTrial: false/*localStorage.getItem('isTrial')*/,
  addons: {ecwid: false},
};

const registerPlanReducer = (state = initState, {type, payload }) => {
  switch(type) {

    case PROFILE_TYPES.CREATE_SUBSCRIPTION_SUCCESS: {
      const {subscription} = payload;
      const billingPeriod = subscription.billingPeriod;
      return {...state, billPeriod: billingPeriod};
    }

    case PROFILE_TYPES.UPDATE_ACCOUNT_INFO_SUCCESS: {
      const user = payload.updated;
      const billingPeriod = user.subscription.billingPeriod;
      const planName = user.plan.type;
      return {...state, billPeriod: billingPeriod, planName};
    }

    case AUTH_TYPES.SET_USER:
    case AUTH_TYPES.AUTH_SUCCESS: {
      const {user} = payload;
      const {billingPeriod, hasEcwidAddon} = user.subscription;
      const planName = user.plan.type;
      const addons = {ecwid: !!hasEcwidAddon}
      return {...state, billPeriod: billingPeriod, planName, addons};
    }

    case REGISTER_PLAN_TYPES.SET_BILL_PERIOD:
    case REGISTER_PLAN_TYPES.SET_PLAN: {
      // localStorage.setItem('billPeriod', payload.billPeriod);
      // localStorage.setItem('planName', payload.planName);
      // localStorage.setItem('isTrial', payload.isTrial);
      return {...state, ...payload}
    }

    case REGISTER_PLAN_TYPES.SET_DEFAULT_STATE: {
      return {...state, planName: payload.planName, billPeriod: payload.billPeriod, addons: payload.addons}
    }

    default:
      return state;
  }
}

export default registerPlanReducer;