export const SET_SALES_ESTIMATOR_TABLE_PARAMS = '@SALESESTIMATOR/SET_SALES_ESTIMATOR_TABLE_PARAMS';
export const FETCH_PRODUCTS = '@SALESESTIMATOR/FETCH_PRODUCTS';
export const FETCH_PRODUCTS_SUCCESS = '@SALESESTIMATOR/FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_ERROR = '@SALESESTIMATOR/FETCH_PRODUCTS_ERROR';
export const GET_SEARCH_PRODUCTS = '@SALESESTIMATOR/GET_SEARCH_PRODUCTS';
export const SEARCH_PRODUCTS = '@SALESESTIMATOR/SEARCH_PRODUCTS';
export const SET_ADD_PRODUCT_FLAG = '@SALESESTIMATOR/SET_ADD_PRODUCT_FLAG';
export const SEARCH_PRODUCTS_SUCCESS = '@SALESESTIMATOR/SEARCH_PRODUCTS_SUCCESS';
export const SEARCH_PRODUCTS_ERROR = '@SALESESTIMATOR/SEARCH_PRODUCTS_ERROR';
export const DELETE_PRODUCT = '@SALESESTIMATOR/DELETE_PRODUCT';
export const SET_DELETE_PRODUCT_FLAG = '@SALESESTIMATOR/SET_DELETE_PRODUCT_FLAG';
export const DELETE_ESTIMATOR_PRODUCT_SUCCESS = '@SALESESTIMATOR/DELETE_ESTIMATOR_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_ERROR = '@SALESESTIMATOR/DELETE_PRODUCT_ERROR';
export const REFRESH_PRODUCT = '@SALESESTIMATOR/REFRESH_PRODUCT';
export const SET_REFRESH_PRODUCT_FLAG = '@SALESESTIMATOR/SET_REFRESH_PRODUCT_FLAG';
export const REFRESH_PRODUCT_SUCCESS = '@SALESESTIMATOR/REFRESH_PRODUCT_SUCCESS';
export const REFRESH_PRODUCT_ERROR = '@SALESESTIMATOR/REFRESH_PRODUCT_ERROR';