import get from 'lodash/get';

const { REACT_APP_CI, REACT_APP_CI_BRANCH } = process.env;
const CRA_PREFIX = 'REACT_APP_';
const PREFIX =
  REACT_APP_CI === 'true'
    ? {
      master: `${CRA_PREFIX}MASTER_`,
      staging: `${CRA_PREFIX}STAGING_`,
      dev: `${CRA_PREFIX}DEV_`,
    }[REACT_APP_CI_BRANCH] || CRA_PREFIX
    : CRA_PREFIX
const getEnv = env => get(process.env, `${PREFIX}${env}`, '');

export const APP_ENV = getEnv('ENV');

export default {
  APP_ENV,
};
