export const DURATION_SELECT_SHAPE = {
  0: {
    title: 'Added date',
    value: 0,
  },
  7:{
    title: '7 days ago',
    value: 7,
  },
  30: {
    title: '30 days ago',
    value: 30,
  },
  90: {
    title: '3 months ago',
    value: 90,
  },
  180: {
    title: '6 months ago',
    value: 180,
  },
  365: {
    title: '1 year ago',
    value: 365,
  },
};