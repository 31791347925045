export const ADD_PRODUCT_SUCCESS = '@sales-spy/ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_ERROR = '@sales-spy/ADD_PRODUCT_ERROR';
export const SALES_SPY_FETCHING = '@sales-spy/SALES_SPY_FETCHING';
export const DELETE_PRODUCT_SUCCESS = '@sales-spy/DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_ERROR = '@sales-spy/DELETE_PRODUCT_ERROR';
export const SET_SALES_SPY_TABLE_PARAMS = '@sales-spy/SET_SALES_SPY_TABLE_PARAMS';
export const FETCH_SALES_SPY_PRODUCTS_SUCCESS = '@sales-spy/FETCH_SALES_SPY_PRODUCTS_SUCCESS';
export const FETCH_SALES_SPY_PRODUCTS_ERROR = '@sales-spy/FETCH_SALES_SPY_PRODUCTS_ERROR';
export const SET_SELECTED_RANGE = '@sales-spy/SET_SELECTED_RANGE';
export const SET_DELETE_PRODUCT_FLAG = '@sales-spy/SET_DELETE_PRODUCT_FLAG';
export const SET_ADD_PRODUCT_FLAG = '@sales-spy/SET_ADD_PRODUCT_FLAG';
// export const SORT_SALES_SPY_TABLE = '@sales-spy/SORT_SALES_SPY_TABLE';
