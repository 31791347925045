import {Container} from "@material-ui/core";
import React from "react";
import {usePlanOverviewStyles} from "./styles/planOverviewStyles";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {MONTHLY, PLANS_OVERVIEW} from "../../constants/PlansFeatures";
import getSymbolFromCurrency from "currency-symbol-map";

export default function PlanOverview() {
  const {billPeriod, planName, addons} = useSelector(state => state.registerPlanReducer);
  const classes = usePlanOverviewStyles();
  const {t} = useTranslation();

  const renderPlanInfo = () => {
    const plan = PLANS_OVERVIEW[planName];
    if(!plan)
      return <></>;

    const getPrice = () => {
      if (billPeriod === MONTHLY) {
        if(addons.ecwid) {
          return plan.pricePerMonth + plan.ecwidPricePerMonth;
        }
        return plan.pricePerMonth;
      }

      if(addons.ecwid) {
        return plan.pricePerYear + plan.ecwidPricePerYear;
      }

      return plan.pricePerYear;
    };

    // const getPriceWithTax = () => {
    //   const price = getPrice();
    //   return (price * 0.21 + price).toFixed(2).replace('.', ',');
    // };

    return (
      <>
        <p className={classes.plan_name}>{t(plan.marketingName)}{t("Package")}</p>
        <div className={classes.plan_price_section}>
          <span className={classes.currency}>
            {getSymbolFromCurrency(plan.currency)}
          </span>
          <span className={classes.plan_price_value}>
            {getPrice()}
          </span>

          <span className={classes.period}>
            {billPeriod === MONTHLY ? `p/${t('m')}` : `p/${t('y')}`}
          </span>
        </div>
        <span className={classes.vatInfo}>
          (Excl 21% VAT)
        </span>

        <div className={classes.featureWrapper}>
          {plan.features.map((f, i) =>
            <div className={classes.plan_feature} key={i}>
              <img src="/images/registration/check-icon.svg" alt=""/>
              <p>{t(f)}</p>
            </div>
          )}
        </div>
        {addons.ecwid &&
          <div className={classes.ecwidFeatureWrapper}>
            <p className={classes.ecwidOptions}>
              {t("+ Extra options")}
            </p>
            <div className={classes.ecwidOptionsWrapper}>
              <div className={classes.ecwidTitle}>
                <span>Ecwid</span>
                <img src="/images/registration/info-icon.svg" alt=""/>
              </div>
              <p className={classes.ecwidHeader}>
                {t(plan.ecwidHeader)}
              </p>

              {plan.ecwidFeatures.map((f, i) =>
                <div className={`${classes.plan_feature} ${classes.mb_8}`} key={i}>
                  <img src="/images/registration/check-icon.svg" alt=""/>
                  <p>{t(f)}</p>
                </div>
              )}
            </div>
          </div>
        }
      </>
    )
  };

  return (
    <Container maxWidth="xs" className={classes.container}>
      <div className={classes.header}>
        <p className={classes.right}>
          {t("My current plan")}
        </p>
        <div className={classes.badgeWrapper}>
          <img src="/images/registration/badge.svg" alt=""/>
          <span className={classes.badgeText}>
            {t("Most popular")}
          </span>
        </div>
      </div>
      <img src="/images/registration/rocket.svg" alt="" className={classes.rocket}/>
      {renderPlanInfo()}
    </Container>
  )
}
