import {FILTER_FORM_SHAPE} from "../constants/nicheFinderFilterShape";

export function toFixed(number, digits=2, forceForInt=false) {
  if (Number.isNaN(number) || number === undefined || number === 'undefined' || number === null) {
    return 'unknown';
  }
  if (forceForInt) {
    return Number(number).toFixed(digits);
  }
  return String(number).split('.').length > 1 ? Number(number).toFixed(digits) : Number(number);
}

export const transformFilterSettingsBeforeFetch = (settings) => settings.reduce((acc,item) => {
  if (item.value && Array.isArray(item.value)) {
    acc[item.key] = {
      min: item.value[0],
      max: item.value[1]
    };
    return acc;
  }

  if (item.minValue !== '' && item.minValue !== null) {
    acc[item.key] = {
      max: acc[item.key]?.max ? acc[item.key]?.max : FILTER_FORM_SHAPE[item.key].allowedRange.max,
      min: item.minValue
    };
  }

  if (item.maxValue !== '' && item.maxValue !== null) {
    acc[item.key] = {
      min: acc[item.key]?.min ? acc[item.key]?.min : FILTER_FORM_SHAPE[item.key].allowedRange.min,
      max: item.maxValue
    };
  }

  return acc;
}, {});

export function truncate(str, num) {
  if (str.length <= num) {
    return str;
  }

  return str.slice(0, num) + '...';
}

export const getErrorMessage = (e) => e?.response?.data?.message || e.message || e?.response?.statusText;

export const getStripeOptions = (paymentFormStyles, fontColor, fontFamily) => ({
  classes: {
    base: paymentFormStyles.stripeElementBase,
    invalid: paymentFormStyles.stripeElementInvalid,
    focus: paymentFormStyles.stripeElementFocus
  },
  style: {
    base: {
      color: fontColor,
      fontFamily: fontFamily,
      lineHeight: '25px',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '18px',
    },
  }
});

export const disableReactDevTools = (() => {
// disable react-dev-tools for this project
  if (process.env.NODE_ENV === "production" && typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object") {
    for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value == "function" ? () => {
      } : null;
    }
  }
})();