import React, {useEffect, useState} from 'react';
import {CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {
  Grid,
  InputAdornment,
  Button,
  TextField,
  withStyles, useTheme,
} from "@material-ui/core";
import {Error} from "@material-ui/icons";
import {
  createSetupIntent,
  createSubscription, setPaymentMethod, stripeSaveOrDeleteSubscriptionDetails,
  updateDefaultPaymentMethod
} from "../../store/actions/profile";
import {useDispatch, useSelector} from "react-redux";
import {SEPA_DEBIT, STRIPE} from "../../constants/paymentMethods";
import {useTranslation} from "react-i18next";
import {setUser} from "../../store/actions/auth";
import {usePaymentFormStyles} from "./styles/paymentFormStyles";
import {getStripeOptions} from "../../utils";
import {getErrorMessage} from "../../utils";



const StyledSubmitButton = withStyles((theme) => ({
  root: {
    marginTop: '20px',
    backgroundColor: theme.palette.systemColors.gold.primary,
    color: theme.palette.systemColors.white.main,
    width: '100%',
    height: '48px',
    fontSize: '18px',
    fontWeight: '700',
    textTransform: 'capitalize',

    '&.MuiButton-root.Mui-disabled': {
      color: 'white',
      opacity: '.4',
    }
  }
}))(Button);

export default function StripeCard({isEdit=false, isUpdate=false, handleCloseDialog=()=> ({})}) {
  const {t} = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const paymentFormStyles = usePaymentFormStyles();
  const theme = useTheme();
  const subscription = useSelector(state => state.authReducer?.user?.subscription);
  const {paymentMethod, done, error} = useSelector(state => state.profileReducer);

  const [loading, setLoading] = useState(false);

  const cardErrors = {
    cardNumber: t('Please enter a valid card number'),
    cardExpiry: t('Please enter a valid date'),
    cardCVC: t('Please enter a valid code'),
  };

  const [cardState, setCardState] = useState({
    cardNumber: {
      touched: false,
      error: null,
    },
    cardExpiry: {
      touched: false,
      error: null,
    },
    cardCVC: {
      touched: false,
      error: null,
    },
    cardName: {
      touched: false,
      value: '',
      error: null,
    },
    hasError: false,
  });

  useEffect(()=> {
    setLoading(false);
  }, [paymentMethod, done, setLoading, error]);

  const onSubscriptionChange = async (subscription, isUpdate) => {
    setLoading(false);
    if (subscription.subError) {
      alert(t("Subscription error", {error: subscription.subError.message}));
      return
    }

    switch(subscription.status) {
      case 'trialing': {
        alert(t("You are starting free trial"));
        window.location.reload();
        break;
      }
      case 'active':
        // Redirect to account page
        alert(t("Success! Redirecting to your account."));
        window.location.reload();
        break;
      case 'incomplete':
        alert(t("Please confirm the payment."));

        // Handle next actions
        //
        // If the status of the subscription is `incomplete` that means
        // there are some further actions required by the customer. In
        // the case of upfront payment (not trial) the payment is confirmed
        // by passing the client_secret of the subscription's latest_invoice's
        // payment_intent.
        //
        // For trials, this works a little differently and requires a call to
        // `stripe.confirmCardSetup` and passing the subscription's
        // pending_setup_intent's client_secret like so:
        //
        //   const {error, setupIntent} = await stripe.confirmCardSetup(
        //     subscription.pending_setup_intent.client_secret
        //   )
        //
        // then handling the resulting error and setupIntent as we do below.
        //
        // This sample does not support subscriptions with trials. Instead, use these docs:
        // https://stripe.com/docs/billing/subscriptions/trials
        const {error} = await stripe.confirmCardPayment(
          subscription.latest_invoice.payment_intent.client_secret,
        )

        if(error) {
          alert(t("Confirmation error", {error: error.message}));
          const {user} = await stripeSaveOrDeleteSubscriptionDetails({subscriptionId: subscription.id});
          dispatch(setUser(user));
        } else {
          setLoading(true);
          const {user, paymentMethod, refundError} = await stripeSaveOrDeleteSubscriptionDetails({subscriptionId: subscription.id});
          setLoading(false);
          if(refundError) {
            alert(t("Refund error for subscription", {...refundError}))
          }

          if(isUpdate) {
            dispatch(setUser(user));
            dispatch(setPaymentMethod(paymentMethod));
            handleCloseDialog();
            return;
          }

          alert(t("Success! Redirecting to your account."));
          window.location.reload();
        }
        break;

      default:
        alert(t('Unknown Subscription status', {status: subscription.status}));
    }
  }

  useEffect(()=> {
    if(subscription.isStripeResponse) {
      return onSubscriptionChange(subscription, isUpdate);
    }
    setLoading(false);
  },[subscription, isUpdate]);

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if(subscription.shouldCancelAtTheEndOfCycle) {
      return alert(t("Already cancel still active"));
    }

    setLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      setLoading(false);
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.

    const cardElement = elements.getElement(CardNumberElement);

    // Use your card Element with other Stripe.js APIs
    const paymentMethodData =  {
      type: 'card',
      card: cardElement
    }
    cardState.cardName.value && (paymentMethodData.billing_details = {name: cardState.cardName.value});

    if (isEdit || paymentMethod?.type === SEPA_DEBIT || isUpdate) {
      let client_secret = null;
      try {
        const res = await createSetupIntent({type: 'card'});
        client_secret = res.client_secret;
      } catch(e) {
        setLoading(false);
        console.log('[error]', e);
        const error = getErrorMessage(e);
        alert(t('Creating payment method error', {error}));
        return;
      }

      const {setupIntent, error} = await stripe.confirmCardSetup(client_secret, {payment_method: paymentMethodData});

      if (error) {
        setLoading(false);
        console.log('[error]', error);
        const message = getErrorMessage(error);
        alert(t('Confirmation error', {error: message}));
        return;
      }
      dispatch(updateDefaultPaymentMethod({setupIntentId: setupIntent.id, type: STRIPE, paymentMethodType: setupIntent.payment_method_types[0], paymentMethodId: setupIntent.payment_method}));
      return
    }

    const {error, paymentMethod: stripePaymentMethod} = await stripe.createPaymentMethod(paymentMethodData);

    if (error) {
      setLoading(false);
      console.log('[error]', error);
      const message = getErrorMessage(error);
      alert(t('Creating payment method error', {error: message}));
      return;
    }

    dispatch(createSubscription({paymentMethodId: stripePaymentMethod.id}));
  };

  const onElementChange = (name) => (e) => {
    const value = name === 'cardName' ? e.target.value : undefined;
    const error = e.error ? cardErrors[name] : null;
    const newFieldValue = {value, error};
    !cardState[name].touched && (newFieldValue.touched = true);

    setCardState({...cardState, [name]: {...cardState[name], ...newFieldValue}, hasError: Boolean(error)});
  }

  return (
    <form
      className={paymentFormStyles.form}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} className={paymentFormStyles.inputWrapper}>
          <div className={paymentFormStyles.legendWrapper}>
            <p className={paymentFormStyles.legend}>{t('Card number')}</p>
          </div>
          <div className={paymentFormStyles.stripeElementWrapperRelative}>
            <CardNumberElement onChange={onElementChange('cardNumber')} options={getStripeOptions(paymentFormStyles, theme.palette.systemColors.text.primary, theme.typography.fontFamily)}/>
            {cardState.cardNumber.touched && cardState.cardNumber.error && <Error color={'error'} className={paymentFormStyles.inputAdornment} />}
            <span className={paymentFormStyles.inputError}>{cardState.cardNumber.touched && cardState.cardNumber.error}</span>
          </div>
        </Grid>

        <Grid item xs={12} justify={"space-between"} container alignItems={"center"}>
          <Grid item xs={5} className={paymentFormStyles.inputWrapper}>
            <div className={paymentFormStyles.legendWrapper}>
              <p className={paymentFormStyles.legend}>{t('Expiration data')}</p>
            </div>
            <div className={paymentFormStyles.stripeElementWrapperRelative}>
              <CardExpiryElement onChange={onElementChange('cardExpiry')} options={getStripeOptions(paymentFormStyles, theme.palette.systemColors.text.primary, theme.typography.fontFamily)} />
              {cardState.cardExpiry.touched && cardState.cardExpiry.error && <Error color={'error'} className={paymentFormStyles.inputAdornment} />}
              <span className={paymentFormStyles.inputError}>{cardState.cardExpiry.touched && cardState.cardExpiry.error}</span>
            </div>
          </Grid>
          <Grid item xs={5} justify={"flex-end"} className={paymentFormStyles.inputWrapper}>
            <div className={paymentFormStyles.legendWrapper}>
              <p className={paymentFormStyles.legend}>{t('CVC/CID code')}</p>
            </div>
            <div className={paymentFormStyles.stripeElementWrapperRelative}>
              <CardCvcElement onChange={onElementChange('cardCVC')} options={getStripeOptions(paymentFormStyles, theme.palette.systemColors.text.primary, theme.typography.fontFamily)}/>
              {cardState.cardCVC.touched && cardState.cardCVC.error && <Error color={'error'} className={paymentFormStyles.inputAdornment} />}
              <span className={paymentFormStyles.inputError}>{cardState.cardCVC.touched && cardState.cardCVC.error}</span>
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} className={paymentFormStyles.nameWrapper}>
          <div className={paymentFormStyles.legendWrapper}>
            <p className={paymentFormStyles.legend}>{t("Name on the card")}</p>
          </div>
          <TextField
            id="card-name"
            fullWidth
            variant='outlined'
            size="small"
            placeholder='xxxxxxxxxxxxxxxxxx'
            InputProps={{
              endAdornment: <InputAdornment position="end">
                {cardState.cardName.touched && !!cardState.cardName.error?.message && <Error color={'error'}/>}
              </InputAdornment>
            }}
            onChange={onElementChange('cardName')}
            value={cardState.cardName.value}
            error={cardState.cardName.touched && !!cardState.cardName.error?.message}
          />
          <span className={paymentFormStyles.inputError}>{cardState.cardName.touched && cardState.cardName.error}</span>
        </Grid>
      </Grid>
      <StyledSubmitButton color="primary" type="submit" disabled={cardState.hasError || loading}>
        {t("Confirm")}
      </StyledSubmitButton>
    </form>
  );
}