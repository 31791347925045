import {PROFILE_TYPES, HTTP_TYPES} from "../types";
import {request} from "../../utils/agent";

export const updateUserInfoAction = (data) => {
  return {
    type: HTTP_TYPES.REQUEST,
    loadingType: PROFILE_TYPES.UPDATE_ACCOUNT_INFO_FETCH,
    responseType: [
      PROFILE_TYPES.UPDATE_ACCOUNT_INFO_SUCCESS,
      PROFILE_TYPES.UPDATE_ACCOUNT_INFO_ERROR,
    ],
    payload: {
      method: 'put',
      url: '/user/me',
      data
    }
  }
}

export const disableDoneFlag = () => ({
  type: PROFILE_TYPES.DISABLE_DONE_FLAG
})

export const resendEmailAction = (data) => {
  return {
    type: HTTP_TYPES.REQUEST,
    loadingType: PROFILE_TYPES.UPDATE_ACCOUNT_INFO_FETCH,
    responseType: [
      PROFILE_TYPES.RESEND_EMAIL_SUCCESS,
      PROFILE_TYPES.RESEND_EMAIL_ERROR,
    ],
    payload: {
      method: 'post',
      url: '/user/me/resend-email',
      data
    }
  }
}

export const createSubscription = (data) => {
  return {
    type: HTTP_TYPES.REQUEST,
    loadingType: PROFILE_TYPES.CREATE_SUBSCRIPTION_FETCH,
    responseType: [
      PROFILE_TYPES.CREATE_SUBSCRIPTION_SUCCESS,
      PROFILE_TYPES.CREATE_SUBSCRIPTION_ERROR,
    ],
    payload: {
      method: 'post',
      url: '/stripe/create-subscription',
      data
    }
  }
}

export const createIdealSubscription = (data) => {
  return {
    type: HTTP_TYPES.REQUEST,
    loadingType: PROFILE_TYPES.CREATE_SUBSCRIPTION_FETCH,
    responseType: [
      PROFILE_TYPES.CREATE_SUBSCRIPTION_SUCCESS,
      PROFILE_TYPES.CREATE_SUBSCRIPTION_ERROR,
    ],
    payload: {
      method: 'post',
      url: '/stripe/create-ideal-subscription',
      data
    }
  }
}

export const fetchUserPaymentMethodDetails = () => {
  return {
    type: HTTP_TYPES.REQUEST,
    loadingType: PROFILE_TYPES.DEFAULT_PAYMENT_METHOD_FETCH,
    responseType: [
      PROFILE_TYPES.DEFAULT_PAYMENT_METHOD_SUCCESS,
      PROFILE_TYPES.DEFAULT_PAYMENT_METHOD_ERROR,
    ],
    payload: {
      method: 'get',
      url: '/user/payment-method-details',
    }
  }
}

export const setPaymentMethod = (paymentMethod) => {
  return {
    type: PROFILE_TYPES.UPDATE_DEFAULT_PAYMENT_METHOD_SUCCESS,
    payload: {paymentMethod}
  }
}

export const updateDefaultPaymentMethod = (data) => {
  return {
    type: HTTP_TYPES.REQUEST,
    loadingType: PROFILE_TYPES.UPDATE_DEFAULT_PAYMENT_METHOD_FETCH,
    responseType: [
      PROFILE_TYPES.UPDATE_DEFAULT_PAYMENT_METHOD_SUCCESS,
      PROFILE_TYPES.UPDATE_DEFAULT_PAYMENT_METHOD_ERROR,
    ],
    payload: {
      method: 'post',
      url: '/user/update-default-payment-method',
      data
    }
  }
}

/**
 *
 * @params {{planName: string, billPeriod: string, addons: {Object.<string, boolean>}} data
 * @returns {Promise<user>}
 */
export const setUserPlanAndBillPeriod = async (data) => await request({
  method: 'post', url: '/user/set-plan-and-period', data
}).then(r => r.data);

export const stripeSaveOrDeleteSubscriptionDetails = async (data) =>
  await request({
    method: 'post', url: '/stripe/save-or-delete-subscription-details', data
  }).then(r => r.data);

export const updateSubscription = async (data) => await request({
  method: 'post', url: '/user/update-subscription', data
}).then(r => r.data);

export const createSetupIntent = async (data) => await request ({
    method: 'post', url: '/stripe/create-setup-intent', data
  }).then(r => r.data);

export const createPayPalSubscription = async () =>
  await request({
    method: 'post', url: '/paypal/create-subscription'
  }).then(r => r.data);

export const updatePayPalDefaultPaymentMethod = async (data) =>
  await request({
    method: 'post',
    url: '/user/update-default-payment-method',
    data
  }).then(r => r.data);

export const saveOrDeleteSubscriptionDetails = async (data) =>
  await request({
    method: 'post', url: '/paypal/save-or-delete-subscription-details', data
  }).then(r => r.data);

export const cancelSubscription = async () =>
  await request({
    method: 'post', url: '/user/cancel-subscription'
  }).then(r => r.data);

export const setSurveyTaken = async () =>
  await request({
    method: 'post', url: '/survey'
  }).then(r => r.data);

export const fetchFrameUrl = async () =>
  await request({
      method: 'get', url: '/ecwid/url'
  }).then(r => r.data);