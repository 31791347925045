export const FETCH_KEYWORDS = '@niche_finder/FETCH_KEYWORDS';
export const FETCH_KEYWORDS_SUCCESS = '@niche_finder/FETCH_KEYWORDS_SUCCESS';
export const FETCH_KEYWORDS_ERROR = '@niche_finder/FETCH_KEYWORDS_ERROR';

export const SET_USER_KEYWORD = '@niche_finder/SET_USER_KEYWORD';
export const GET_CATEGORIES = '@categories/GET_CATEGORIES';
export const UPDATE_CATEGORIES_ERROR = '@categories/UPDATE_CATEGORIES_ERROR';
export const UPDATE_CATEGORIES_SUCCESS = '@categories/UPDATE_CATEGORIES_SUCCESS';

export const SET_SELECTED_CATEGORIES = '@categories/SET_SELECTED_CATEGORIES';
export const SET_NICHE_TABLE_PARAMS = '@niche_finder/SET_NICHE_TABLE_PARAMS';
export const SET_NICHE_TABLE_FILTER = '@niche_finder/SET_NICHE_TABLE_FILTER';
export const RESET_NICHE_TABLE_FILTER = '@niche_finder/RESET_NICHE_TABLE_FILTER';
export const OPEN_NICHE_FINDER_FILTER = '@niche_finder/OPEN_NICHE_FINDER_FILTER';
export const CLOSE_NICHE_FINDER_FILTER = '@niche_finder/CLOSE_NICHE_FINDER_FILTER';
export const OPEN_NICHE_FINDER_DETAILS = '@niche_finder/OPEN_NICHE_FINDER_DETAILS';
export const CLOSE_NICHE_FINDER_DETAILS = '@niche_finder/CLOSE_NICHE_FINDER_DETAILS';
export const GET_DETAILS = '@niche_finder/GET_NICHE_FINDER_DETAILS';
