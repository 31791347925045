import React, {useState} from 'react';
import {useElements, useStripe, IdealBankElement} from '@stripe/react-stripe-js';
import {usePaymentFormStyles} from "./styles/paymentFormStyles";
import {
  Grid,
  Button,
  withStyles, useTheme
} from "@material-ui/core";
import {Error} from "@material-ui/icons";
import {createSetupIntent} from "../../store/actions/profile";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getStripeOptions} from "../../utils";
import {getErrorMessage} from "../../utils";


const StyledSubmitButton = withStyles((theme) => ({
  root: {
    marginTop: '20px',
    backgroundColor: theme.palette.systemColors.gold.primary,
    color: theme.palette.systemColors.white.main,
    width: '100%',
    height: '48px',
    fontSize: '18px',
    fontWeight: '700',
    textTransform: 'capitalize',

    '&.MuiButton-root.Mui-disabled': {
      color: 'white',
      opacity: '.4',
    }
  }
}))(Button);

export default function Ideal({isEdit=false, isUpdate=false, handleCloseDialog=()=>({}) }) {
  const {t} = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const email = useSelector(state => state.authReducer?.user?.email);
  const shouldCancelAtTheEndOfCycle = useSelector(state => state.authReducer?.user?.subscription.shouldCancelAtTheEndOfCycle);
  const paymentFormStyles = usePaymentFormStyles();
  const theme = useTheme();
  const [selectedBank, setSelectedBank] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);


  const handleSubmit = async (event) => {
    // Block native form submission.
    try {
      event.preventDefault();
      if(shouldCancelAtTheEndOfCycle) {
        return alert(t("Already cancel still active"));
      }
      setLoading(true);

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        setLoading(false);
        return;
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const idealBankElement = elements.getElement(IdealBankElement);

      const {client_secret} = await createSetupIntent({type: 'ideal'});
      const {error, ...rest} = await stripe.confirmIdealSetup(client_secret, {
        payment_method: {
          ideal: idealBankElement,
          billing_details: {
            name: "John",
            email,
          }
        },
        return_url: (isEdit || isUpdate) ? `${window.location}` : `${window.location.origin}/ideal-success/`
      });

      console.log(rest);

      if (error) {
        setLoading(false);
        setError(error.message);
        console.log('[error]', error);
        alert('Error');
        return;
      } else {
        setLoading(false);
        setError(null);
      }
    } catch (e) {
      const error = getErrorMessage(e);
      alert(t('Creating payment method error', {error}));
    }
  };

  const onElementChange = (e) => {
    setSelectedBank(e.value);
  }

  return (
    <form
      className={paymentFormStyles.form}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} className={paymentFormStyles.inputWrapper}>
          <div className={paymentFormStyles.stripeElementWrapperRelative}>
            <IdealBankElement onChange={onElementChange} options={getStripeOptions(paymentFormStyles, theme.palette.systemColors.text.primary, theme.typography.fontFamily)}/>
            {error && <Error color={'error'} className={paymentFormStyles.inputAdornment} />}
            <span className={paymentFormStyles.idealInputError}>{error}</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <p className={paymentFormStyles.sepaConfirm}>
            {t("By providing your payment information and confirming this payment, you authorise (A) DSE and Stripe, our payment service provider and/or PPRO, its local service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.")}
          </p>
        </Grid>
      </Grid>
      <StyledSubmitButton color="primary" type="submit" disabled={!selectedBank || loading}>
        {t("Confirm")}
      </StyledSubmitButton>
    </form>
  );
}