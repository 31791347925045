import SimpleDialog from "../../Dialog";
import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button, withStyles } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";

const StyledResultButton = withStyles((theme) => ({
  root: {
    marginBottom: "10px",
    borderRadius: "4px",
    background: theme.palette.systemColors.orange.primary,
    color: "white",
    padding: "12px 24px",
    fontSize: "16px",
    lineHeight: "25px",
    fontWeight: "bold",
    textTransform: "initial",
  },
}))(Button);

const StyledCancelButton = withStyles((theme) => ({
  root: {
    marginBottom: "10px",
    borderRadius: "4px",
    background: theme.palette.systemColors.black.primary,
    color: "white",
    padding: "12px 24px",
    fontSize: "16px",
    lineHeight: "25px",
    fontWeight: "bold",
    textTransform: "initial",
  },
}))(Button);

const style = makeStyles((theme) => ({
  dialogRoot: {
    "& .MuiPaper-root": {
      padding: "24px",
      maxWidth: "409px",
      borderRadius: "8px",
      boxShadow: "0px 0px 32px rgba(0, 0, 0, 0.1)",
      justifyItems: "center",
      alignItems: "center",
    },
  },
  dialogHeader: {
    padding: 0,
    ...theme.typography.secondary,
    marginBottom: "8px",
    textAlign: "center",
    fontSize: "27px",
    lineHeight: "32px",
    fontWeight: "bold",
    // }
  },
  dialogTitle: {
    fontWeight: "bold",
  },
  dialogSalesCount: {
    fontSize: "16px",
    lineHeight: "22px",
    color: theme.palette.systemColors.text.primary,
    fontStyle: "italic",
    marginBottom: "24px",
  },
  dialogText: {
    fontSize: "18px",
    lineHeight: "24px",
    color: theme.palette.systemColors.text.primary,
    textAlign: "center",
    marginBottom: "24px",
  },
  dialogSalutation: {
      fontSize: "18px",
      lineHeight: "24px",
      color: theme.palette.systemColors.text.primary,
      textAlign: "center",
    },
}));

export default function SessionTimeoutDialog({ open, handleCloseDialog }) {
  const classes = style();
  const { t } = useTranslation();
  const userName = useSelector((state) => state.authReducer?.user?.name);

  return (
    <SimpleDialog
      onClose={() => handleCloseDialog(false)}
      open={open}
      title={t("Session Time-out")}
      titleClass={classes.dialogHeader}
      rootClass={classes.dialogRoot}
    >
      <div className={classes.dialogSalutation}>
        {t(`Hey ${userName},`)}
      </div>
      <div className={classes.dialogText}>
        {t("We have noticed that you've been gone for a while.")}
      </div>
      <div className={classes.dialogText}>
        {t(
          "Would you want to continue or is it time for a well-deserved break?"
        )}
        <span style={{ marginLeft: 10}}>
        <img style={{ marginBottom: 10}} src="/images/icons/coffee.svg" alt={'Logo'} height={"22px"}/>
        </span>
      </div>

  
        <StyledResultButton
          variant="contained"
          onClick={() => handleCloseDialog(true)}
        >
          {t("Continue")}
        </StyledResultButton>
        <StyledCancelButton
          onClick={() => {
            localStorage.removeItem("userLastActivity");
            localStorage.removeItem("autoPopupUserGuideClosedOnce");
            localStorage.removeItem("token");
            window.location.reload();
          }}
        >
          {t("Take a break")}
        </StyledCancelButton>

    </SimpleDialog>
  );
}

SessionTimeoutDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func,
};
