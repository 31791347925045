import { SALES_SPY_TYPES } from "../types";
import { DURATION_SELECT_SHAPE } from "../../constants/durationSelectCheckboxShape";
// import {get as _get} from 'lodash';

const initState = {
  loading: false,
  addProduct: false,
  error: false,
  errorMessage: '',
  successMessage: '',
  addedProductTitle: '',
  products: [],
  history: false,
  orderBy: null,
  order: null,
  page: 0,
  pageSize: 10,
  totalCount: 0,
  deleteProduct: false,
  deletedProductTitle: '',
  selectedRange: DURATION_SELECT_SHAPE[0].value,
};


// const orderByPaths = {
//   price: 'history.price',
//   salesVolume: 'history.salesVolume',
//   rating: 'history.rating',
//   revenuePotential: 'history.revenuePotential'
// };

const salesSpyReducer = (state = initState, { type, payload, message, reqData }) => {
  switch (type) {
    case SALES_SPY_TYPES.SET_SELECTED_RANGE: {
      return {
        ...state, selectedRange: payload.selectedRange
      }
    }
    case SALES_SPY_TYPES.SET_SALES_SPY_TABLE_PARAMS: {
      const { order, orderBy, page, pageSize } = payload;
      return {
        ...state, order, orderBy, page, pageSize
      }
    }

    case SALES_SPY_TYPES.SALES_SPY_FETCHING: {
      return {
        ...state, loading: true, addProduct: false, error: false, errorMessage: '', successMessage: '', addedProductTitle: '',
      }
    }

    // case SALES_SPY_TYPES.SORT_SALES_SPY_TABLE: {
    //   const path = orderByPaths[state.orderBy];
    //   if(!path) {
    //     return state;
    //   }
    //   const sorted = state.products.result.sort((a, b) => {
    //     const aProp = _get(a, path, 0);
    //     const bProp = _get(b, path, 0);
    //     return state.order === 'asc' ?  aProp - bProp : bProp - aProp;
    //   });
    //
    //   return {
    //     ...state, products: {result: sorted, history: state.products.history}
    //   }
    // }

    case SALES_SPY_TYPES.DELETE_PRODUCT_ERROR:
    case SALES_SPY_TYPES.ADD_PRODUCT_ERROR:
    case SALES_SPY_TYPES.FETCH_SALES_SPY_PRODUCTS_ERROR: {
      return {
        ...state, addProduct: true, loading: false, error: true, errorMessage: message, successMessage: '',
      }
    }
    case SALES_SPY_TYPES.FETCH_SALES_SPY_PRODUCTS_SUCCESS:
    case SALES_SPY_TYPES.ADD_PRODUCT_SUCCESS: {
      const successMessage = message;
      let addedProductTitle = reqData?.data?.product?.title;
      const { totalCount, result, page, history = false, createdSalesSpy } = payload;

      // When product is added using asin 
      if (typeof reqData.meta === 'string') {
        const addedProduct = reqData.meta && payload.result.find((e) => e.asin === reqData.meta);

        if (!addedProduct) {
          addedProductTitle = createdSalesSpy?.product?.title;
        } else {
          addedProductTitle = addedProduct?.product?.title;
        }
      }

      const newState = { ...state, addProduct: true, loading: false, error: false, errorMessage: '', successMessage, products: result, totalCount, history: Boolean(history) }
      addedProductTitle && (newState.addedProductTitle = addedProductTitle);
      return newState;
    }

    // case SALEorderByPathsS_SPY_TYPES.ADD_PRODUCT_SUCCESS: {
    //   const lastProductTitle = reqData?.data?.product?.title;
    //   const {totalCount, result, history=false} = payload;
    //
    //   const product = payload.result;
    //   return {
    //     ...state, products: [...state.products, product], done: true, loading: false, error: false, errorMessage: '', successMessage: '', lastProductTitle, totalCount: ++state.totalCount
    //   }
    // }
    case SALES_SPY_TYPES.DELETE_PRODUCT_SUCCESS: {
      const { asin } = reqData.meta;
      const deletedProduct = state.products.find((e) => e.asin === asin);
      const deletedProductTitle = deletedProduct?.product?.title;
      const updated = state.products.filter((e) => e.asin !== asin);
      let pageIndex;

      /* If the deleted product is the last on the page, set the page index 
         to the previous page after ensuring that the current page is not 
         the first page.
      */
      if (updated.length === 0 && state.page !== 0) {
        pageIndex = state.page - 1
      } else {
        pageIndex = state.page
      }

      return { ...state, deleteProduct: true, deletedProductTitle: deletedProductTitle, loading: false, error: false, errorMessage: '', successMessage: "", products: updated, page: pageIndex, totalCount: --state.totalCount }
    }

    case SALES_SPY_TYPES.SET_DELETE_PRODUCT_FLAG: {
      return { ...state, deleteProduct: false, deletedProductTitle: "" }
    }

    case SALES_SPY_TYPES.SET_ADD_PRODUCT_FLAG: {
      return { ...state, addProduct: false, addedProductTitle: "" }
    }

    default:
      return state;
  }
}

export default salesSpyReducer;