import {useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {CircularProgress, Container} from "@material-ui/core";
import {CheckCircle, Error} from "@material-ui/icons";
import {saveOrDeleteSubscriptionDetails as serviceSaveOrDeleteSubscriptionDetails} from "../../store/actions/profile";
import {useTranslation} from "react-i18next";
import {usePaymentReturnStyles} from "./styles/paymentReturnStyles";
import {PRODUCT_FINDER_ROUTES} from "../../constants/routes";

export default function PayPalReturn ({isCancel}) {
  const {t} = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const subscriptionId = query.get('subscription_id');
  const baToken = query.get('ba-token');
  const token = query.get('token');


  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const classes = usePaymentReturnStyles();

  const getTrialEndDate = () => {
    const today = new Date();
    return new Date(today.setDate(today.getDate() + 14)).toLocaleDateString().replaceAll(".", "-");
  }

  useEffect(() => {
    setLoading(true);
    if(subscriptionId) {
      const saveOrDeleteSubscriptionDetails = async() => {
        try {
          await serviceSaveOrDeleteSubscriptionDetails({isCancel});
          setLoading(false);
          window.location.replace(PRODUCT_FINDER_ROUTES.NICHE_PICKR);
        } catch (e) {
          setError(e.message);
          alert(`Error, ${e}`);
        }
      }
      saveOrDeleteSubscriptionDetails();
    } else {
      window.location.replace('/');
    }
  }, [subscriptionId, baToken, token, setLoading]);

  return (
    <>
      <div className={classes.wrapper}>
        <Container component="main" maxWidth="xs" className={classes.container}>
          <div className={classes.header}>
            <p className={classes.title}>
              {t('Choose a payment method')}
            </p>
            <p className={classes.subTitle}>
              {t("Your 14-day trial is completely FREE and you will not be charged until . You can switch to a different plan or cancel anytime.", {endTime: getTrialEndDate()})}
            </p>
          </div>
          <div className={classes.formContainer}>
            {<div className={error ? classes.errorBanner : classes.successBanner}>
              {loading ?
                <CircularProgress /> :
                error ? <Error className={'bannerIcon'} style={{ fontSize: 32 }}/> : <CheckCircle className={'bannerIcon'} style={{ fontSize: 32 }}/>
              }
              <div className={'bannerContent'}>
                <p className={'bannerText'}>{!error ? t("Getting subscription data success") : t('Error')}</p>
                <p className={'bannerText'}>{error}</p>
                <p className={'bannerText'}>{loading && t('Waiting for subscription status...')}</p>
              </div>
            </div>
            }
          </div>
        </Container>
      </div>
    </>
  );
}