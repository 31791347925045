import {makeStyles} from "@material-ui/core";

export const usePlanOverviewStyles = makeStyles((theme) => ({
  container: {
    minHeight: "860px",
    marginRight: "0",
    background:
      "linear-gradient(180deg, #0055E3 0%, rgba(0, 85, 227, 0) 61.46%), #003CB4",
    borderRadius: "8px",

    color: theme.palette.systemColors.white.main,
    padding: 0,
    display: 'flex',
    maxWidth: '406px',
    boxShadow: theme.props.customShadows.soft,
    flexDirection: 'column',
    alignItems: 'center',
  },

  vatInfo: {
    fontStyle: 'italic',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  right: {
    fontSize: '16px',
    lineHeight: '24px',
    marginLeft: '20px',
    fontWeight: '600',
  },
  badgeWrapper: {
    padding: "16px 8px",
    backgroundColor: theme.palette.systemColors.green.primary,
    display: 'flex',
    alignItems: 'center',
    borderBottomLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  badgeText: {
    fontSize: '16px',
    lineHeight: '20px',
    marginLeft: '4px',
  },
  rocket: {
    margin: "24px 0",
  },
  plan_name: {
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '24px',
    marginBottom: '17px',
  },

  plan_price_section: {
    display: "flex",
    alignItems: "center",
    marginBottom: "24px",
  },

  plan_price_value: {
    fontWeight: "bold",
    fontSize: "28px",
    lineHeight: "20px",
    textAlign: "center",
    letterSpacing: "-1px",
    marginTop: '4px',
  },

  period: {
    fontWeight: "bold",
    marginLeft: "4px",
    marginTop: '-4px',
    fontSize: "18px",
    lineHeight: "20px",
  },

  currency: {
    fontSize: "18px",
    lineHeight: "20px",
    textAlign: "center",
    marginRight: '4px',
  },

  featureWrapper: {
    margin: "0 13px 0 20px",
    borderTop: '1px solid #3c6dd5',
    borderBottom: '1px solid #3c6dd5',
    padding: "24px 0",
  },

  plan_feature: {
    display: "flex",
    fontSize: "16px",
    lineHeight: "20px",
    marginBottom: '12px',

    "& img": {
      marginRight: "12px",
    }
  },

  mb_8: {
    marginBottom: '8px',
  },

  ecwidFeatureWrapper: {
    padding: '12px 18px 20px 18px',
    width: "100%",
  },

  ecwidOptions: {
    marginBottom: '4px',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
  },

  ecwidOptionsWrapper: {
    background: "#3050bc",
    borderRadius: '8px',
    padding: '12px 0 20px 20px',
  },
  ecwidTitle: {
    display: 'flex',
    alignItems: 'center',

    "& span": {
      fontSize: '20px',
      lineHeight: '24px',
      marginRight: '8px',
    }
  },
  ecwidHeader: {
    margin: '4px 0 11px 0px',
  }
}));