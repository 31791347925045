import {useStripe} from "@stripe/react-stripe-js";
import {useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {createIdealSubscription} from "../../store/actions/profile";
import {useDispatch, useSelector} from "react-redux";
import {CircularProgress, Container} from "@material-ui/core";
import {CheckCircle, Error} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {usePaymentReturnStyles} from "./styles/paymentReturnStyles";
import {PRODUCT_FINDER_ROUTES} from "../../constants/routes";


export default function IdealReturn () {
  const {t} = useTranslation();
  const stripe = useStripe();
  const dispatch = useDispatch();
  const query = new URLSearchParams(useLocation().search);
  const clientSecret = query.get('setup_intent_client_secret');
  const isError = (query.get('redirect_status') === 'failed');
  const subscription = useSelector(state => state.authReducer?.user?.subscription);
  const [loading, setLoading] = useState(true);
  const classes = usePaymentReturnStyles();

  const getTrialEndDate = () => {
    const today = new Date();
    return new Date(today.setDate(today.getDate() + 14)).toLocaleDateString().replaceAll(".", "-");
  }

  const [message, setMessage] = useState('');

  const onSubscriptionChange = async (subscription) => {
    setLoading(false);
    if (subscription.subError) {
      alert(subscription.subError.message);
      return
    }

    switch(subscription.status) {
      case 'trialing': {
        alert(t("You are starting free trial"));
        window.location.replace(PRODUCT_FINDER_ROUTES.NICHE_PICKR);
        break;
      }
      case 'active':
        // Redirect to account page
        alert(t("Success! Redirecting to your account."));
        window.location.replace(PRODUCT_FINDER_ROUTES.NICHE_PICKR);
        break;
      case 'incomplete':
        alert(t("Please confirm the payment."));

        // Handle next actions
        //
        // If the status of the subscription is `incomplete` that means
        // there are some further actions required by the customer. In
        // the case of upfront payment (not trial) the payment is confirmed
        // by passing the client_secret of the subscription's latest_invoice's
        // payment_intent.
        //
        // For trials, this works a little differently and requires a call to
        // `stripe.confirmCardSetup` and passing the subscription's
        // pending_setup_intent's client_secret like so:
        //
        //   const {error, setupIntent} = await stripe.confirmCardSetup(
        //     subscription.pending_setup_intent.client_secret
        //   )
        //
        // then handling the resulting error and setupIntent as we do below.
        //
        // This sample does not support subscriptions with trials. Instead, use these docs:
        // https://stripe.com/docs/billing/subscriptions/trials
        const {error} = await stripe.confirmCardPayment(
          subscription.latest_invoice.payment_intent.client_secret,
        )

        if(error) {
          alert(t("Confirmation error", {error: error.message}));
        } else {
          alert(t("Success! Redirecting to your account."));
          window.location.replace(PRODUCT_FINDER_ROUTES.NICHE_PICKR);
        }
        break;
      default:
        alert(t('Unknown Subscription status', {status: subscription.status}));
    }
  }

  useEffect(()=> {
    if(subscription?.isStripeResponse) {
      return onSubscriptionChange(subscription);
    }

    if(subscription.error) {
      setLoading(false);
    }
  },[subscription]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const fetchSetupIntent = async () => {
      const {setupIntent} = await stripe.retrieveSetupIntent(clientSecret);


      if (isError) {
        setLoading(false);
        return setMessage(setupIntent.last_setup_error.message);
      }

      setMessage(t("Setup intent status",{status: setupIntent.status}));
      setLoading(true);
      dispatch(createIdealSubscription());
    };
    fetchSetupIntent();
  }, [clientSecret, stripe, setMessage, isError]);

  return (
    <>
    <div className={classes.wrapper}>
      <Container component="main" maxWidth="xs" className={classes.container}>
        <div className={classes.header}>
          <p className={classes.title}>
            {t("Choose a payment method")}
          </p>
          <p className={classes.subTitle}>
            {t("Your 14-day trial is completely FREE and you will not be charged until . You can switch to a different plan or cancel anytime.", {endTime: getTrialEndDate()})}
          </p>
        </div>
        <div className={classes.formContainer}>
          {<div className={isError || subscription.error ? classes.errorBanner : classes.successBanner}>
            {loading ?
              <CircularProgress /> :
              (isError || subscription.error) ? <Error className={'bannerIcon'} style={{ fontSize: 32 }}/> : <CheckCircle className={'bannerIcon'} style={{ fontSize: 32 }}/>
            }
            <div className={'bannerContent'}>
              <p className={'bannerText'}>{!isError ? t('Bank authentication success') : t('Error')}</p>
              <p className={'bannerText'}>{message}</p>
              <p className={'bannerText'}>{loading && t('Waiting for subscription creation ...')}</p>
              {subscription.error && <p className={'bannerText'}>{t('Subscription error', {error: subscription.error})}</p>}
            </div>
          </div>
          }
        </div>
      </Container>
    </div>
    </>
  );
}