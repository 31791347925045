//TODO import here types that must notify user about smt
import { FETCH_KEYWORDS_ERROR, UPDATE_CATEGORIES_ERROR } from "./keywords";
import { FETCH_SALES_SPY_PRODUCTS_ERROR, DELETE_PRODUCT_ERROR, ADD_PRODUCT_SUCCESS, ADD_PRODUCT_ERROR, DELETE_PRODUCT_SUCCESS } from "./sales-spy";
import { RESEND_EMAIL_SUCCESS, RESEND_EMAIL_ERROR, UPDATE_ACCOUNT_INFO_ERROR, UPDATE_DEFAULT_PAYMENT_METHOD_ERROR, DEFAULT_PAYMENT_METHOD_ERROR, CREATE_SUBSCRIPTION_ERROR } from "./profile";
import { SEARCH_PRODUCTS_SUCCESS, DELETE_ESTIMATOR_PRODUCT_SUCCESS, REFRESH_PRODUCT_SUCCESS, FETCH_PRODUCTS_ERROR, SEARCH_PRODUCTS_ERROR, REFRESH_PRODUCT_ERROR } from "./sales-estimator";
import { CREATE_BOL_PACKAGE_SIZE_SUCCESS, DELETE_BOL_PACKAGE_SIZE_SUCCESS } from "./calculator"
import { SEARCH_ALIBABA_PRODUCTS_ERROR } from "./alibaba"

const UPDATE_DETAILS_SUCCESS = '@response/UPDATE_DETAILS_SUCCESS';
const UPDATE_DETAILS_ERROR = '@response/UPDATE_DETAILS_ERROR';

export {
  UPDATE_DEFAULT_PAYMENT_METHOD_ERROR,
  DEFAULT_PAYMENT_METHOD_ERROR,
  CREATE_SUBSCRIPTION_ERROR,
  ADD_PRODUCT_SUCCESS,
  UPDATE_ACCOUNT_INFO_ERROR,
  RESEND_EMAIL_SUCCESS,
  RESEND_EMAIL_ERROR,
  ADD_PRODUCT_ERROR,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_ERROR,
  FETCH_KEYWORDS_ERROR,
  FETCH_SALES_SPY_PRODUCTS_ERROR,
  UPDATE_CATEGORIES_ERROR,
  UPDATE_DETAILS_SUCCESS,
  UPDATE_DETAILS_ERROR,
  SEARCH_PRODUCTS_SUCCESS,
  DELETE_ESTIMATOR_PRODUCT_SUCCESS,
  REFRESH_PRODUCT_SUCCESS,
  FETCH_PRODUCTS_ERROR,
  SEARCH_PRODUCTS_ERROR,
  REFRESH_PRODUCT_ERROR,
  CREATE_BOL_PACKAGE_SIZE_SUCCESS,
  DELETE_BOL_PACKAGE_SIZE_SUCCESS,
  SEARCH_ALIBABA_PRODUCTS_ERROR
};
