import {makeStyles} from "@material-ui/core";

export const usePaymentReturnStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: theme.palette.pageBackground.main
  },
  container: {
    padding: 0,
    display: 'flex',
    maxWidth: '406px',
    backgroundColor: 'white',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    backgroundColor: theme.palette.systemColors.blue.primary,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    color: 'white',
    alignItems: 'center',
    padding: '24px 40px 24px 40px'
  },
  logo: {
    marginTop: '24px'
  },
  title: {
    ...theme.typography.secondary,
    margin: 0,
    fontSize: '24px',
    lineHeight: '24px',
    marginBottom:'4px',
    textAlign: 'center',
    fontWeight: 800,
  },
  inputWrapper: {
    paddingTop: 0,
    paddingBottom: 0
  },
  subTitle: {
    margin: 0,
    fontSize: '18px',
    textAlign: 'center',
    lineHeight: '20px',
  },
  formContainer: {
    minHeight: '405px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '24px 40px 20px 40px',
  },

  errorBanner: {
    backgroundColor: theme.palette.systemColors.red.ultraLight,
    borderRadius: '4px',
    padding: '8px 16px 12px 16px',
    display: 'flex',
    alignItems: 'center',

    '& .bannerIcon': {
      color: theme.palette.systemColors.red.primary,
      fontSize: '32px',
    },

    '& .bannerContent': {
      color: theme.palette.systemColors.red.primary,
      marginLeft: '16px',
    },

    '& .bannerHeader': {
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 'bold'
    },

    '& .bannerText': {
      marginTop: '12px',
      fontSize: '14px',
      lineHeight: '16px',
    },
  },

  successBanner: {
    backgroundColor: theme.palette.systemColors.green.light,
    borderRadius: '4px',
    padding: '8px 16px 12px 16px',
    display: 'flex',
    alignItems: 'center',

    '& .bannerIcon': {
      color: theme.palette.systemColors.green.primary,
      fontSize: '32px',
    },

    '& .bannerContent': {
      color: theme.palette.systemColors.green.primary,
      marginLeft: '16px',
    },

    '& .bannerHeader': {
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 'bold'
    },

    '& .bannerText': {
      marginTop: '12px',
      fontSize: '14px',
      lineHeight: '16px',
    },
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
}));