import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from "redux-thunk";
import rootReducer from './reducers';
import rootSaga from './sagas';
import unAuthorizedMiddleware from './middleware/auth-middleware';
import { APP_ENV } from '../config/app';

export default (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [];
  middleware.push(thunk);
  middleware.push(sagaMiddleware);
  middleware.push(unAuthorizedMiddleware);
  let enhancer = null;

  if (APP_ENV !== 'production') {
    enhancer = compose(
      applyMiddleware(...middleware),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : val => val,
    )
  } else {
    enhancer = applyMiddleware(...middleware);
  }

  const store = createStore(rootReducer, initialState, enhancer);

  sagaMiddleware.run(rootSaga, store.dispatch, store.getState);

  if (module.hot) {
    module.hot.accept(
      './reducers',
      () => store.replaceReducer(require('./reducers').default)
    )
  }

  return store;
};