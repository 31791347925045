export const FILTER_FORM_SHAPE = {
  'buyerDemand': {
    title: 'Buyer Demand',
    key: 'buyerDemand',
    type: 'range',
    info: 'Buyer demand is calculated with the use of various data points how likely people are to buy products within the suggested niche. We base this number on monthly sales in combination with search trends from customers for the keyword.',
    value: {
      min: 0,
      max: 100,
    },
    allowedRange: {
      min: 0,
      max: 100,
    }
  },
  'nicheAverage': {
    title: 'Niche Score',
    key: 'nicheAverage',
    type: 'range',
    info: 'Niche score is the final result from a weighted average of the four pillars we use to define what a great product for ecommerce is.',
    value: {
      min: 0,
      max: 100,
    },
    allowedRange: {
      min: 0,
      max: 100,
    }
  },
  'competitionOpportunity': {
    title: 'Competitor Check',
    key: 'competitionOpportunity',
    type: 'range',
    info: 'Competition check takes the strength and sales from competitors into account. How likely can you sell a product in this niche. If other sellers are having great optimized listings, then the chance to sell for a new enterer becomes harder, we mitigate this risk by looking at the probability to create a better listing that the incumbent/ existing listings.',
    value: {
      min: 0,
      max: 100,
    },
    allowedRange: {
      min: 0,
      max: 100,
    }
  },
  'averageListingStrength': {
    title: 'Average Listing Strength',
    key: 'averageListingStrength',
    type: 'range',
    info: 'The strength of a listing is dependent on numerous factors of a listing. For example, the optimization of keywords, EBC, images and reviews and review scores. The better this score, the more likely a listing is to convert into sales.',
    value: {
      min: 0,
      max: 10,
    },
    step: 0.1,
    allowedRange: {
      min: 0,
      max: 10,
    }
  },
  'investmentSweetspot': {
    title: 'Financial Fit',
    key: 'investmentSweetspot',
    type: 'range',
    info: 'Financial fit is calculated to see what amount of inventory levels and the associated costs a new seller should consider to have a relative market share that corresponds to the return on investment (ROI). This number is adjustable with the use of the filters below for more risk seeking sellers or safe players. The reality shows that some products sell at way higher pace but have a lower ROI; or on the contrary they have a very high ROI but only sell a little per month. This requires different inventory levels. Dropshippers can also select this filter to be 0, since inventory is not relevant to them, although there remain valuable insights in this measure.',
    value: {
      min: 0,
      max: 100,
    },
    allowedRange: {
      min: 0,
      max: 100,
    }
  },
  'revenuePotential': {
    title: 'Profit Potential',
    key: 'revenuePotential',
    type: 'range',
    info: 'The essence of revenue potential is to show you an estimate of profit that a seller can make within this niche. We take the sale pace and price into account and calculate the average sales revenue for a listing.',
    value: {
      min: 0,
      max: 100,
    },
    allowedRange: {
      min: 0,
      max: 100,
    }
  },
  'searchVolume': {
    title: 'Search Volume',
    key: 'searchVolume',
    type: 'input',
    info: 'Estimated number of exact match Amazon searches for the suggested keyword in this niche.',
    value: {
      min: '',
      max: '',
    },
    allowedRange: {
      min: 0,
      max: 10000000,
    }
  },
  'launchBudget': {
    title: 'Launch Budget',
    key: 'launchBudget',
    type: 'input',
    info: 'The launch budget is an adjustable measure where you can mitigate your risk. If you want to look for big hitters or a small starter, here you can filter your strategy.',
    value: {
      min: '',
      max: '',
    },
    step: 0.01,
    allowedRange: {
      min: 0,
      max: 1000000,
    }
  },
  'averageRevenue': {
    title: 'Average Revenue',
    key: 'averageRevenue',
    type: 'input',
    info: 'Average gross revenue (before tax, commissions and shipping costs) per month for the specified niche keyword.',
    value: {
      min: '',
      max: '',
    },
    step: 0.01,
    allowedRange: {
      min: 0,
      max: 1000000,
    },
  },
  'averageSales': {
    title: 'Average Sales',
    key: 'averageSales',
    type: 'input',
    info: 'The average number of products sold per month for the related listings in this niche.',
    value: {
      min: '',
      max: '',
    },
    allowedRange: {
      min: 0,
      max: 100000,
    },
  },
  'averagePrice': {
    title: 'Average Price',
    key: 'averagePrice',
    type: 'input',
    info: 'The average price of products for the listings suggested by the keyword.',
    value: {
      min: '',
      max: '',
    },
    step: 0.01,
    allowedRange: {
      min: 0,
      max: 1000000,
    },
  },
  'averageRating': {
    title: 'Average Rating',
    key: 'averageRating',
    type: 'input',
    info: 'The average number of ratings a keyword has which is used in the listing strength calculation. The more ratings and the higher the rating itself, the more competition on this listing.',
    value: {
      min: '',
      max: '',
    },
    step: 0.1,
    allowedRange: {
      min: 0,
      max: 5,
    },
  },
}