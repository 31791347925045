export const SET_ALIBABA_TABLE_PARAMS = '@alibaba/SET_ALIBABA_TABLE_PARAMS';
export const OPEN_ALIBABA_DETAILS = '@alibaba/OPEN_ALIBABA_DETAILS';
export const CLOSE_ALIBABA_DETAILS = '@alibaba/CLOSE_ALIBABA_DETAILS';
export const OPEN_ALIBABA_FILTER = '@alibaba/OPEN_ALIBABA_FILTER';
export const CLOSE_ALIBABA_FILTER = '@alibaba/CLOSE_ALIBABA_FILTER';
export const SET_ALIBABA_TABLE_FILTER = '@alibaba/SET_ALIBABA_TABLE_FILTER';
export const RESET_ALIBABA_TABLE_FILTER = '@alibaba/RESET_ALIBABA_TABLE_FILTER';
export const GET_SEARCH_PRODUCTS = '@alibaba/GET_SEARCH_PRODUCTS';
export const SEARCH_PRODUCTS = '@alibaba/SEARCH_PRODUCTS';
export const SEARCH_PRODUCTS_SUCCESS = '@alibaba/SEARCH_PRODUCTS_SUCCESS';
export const SEARCH_ALIBABA_PRODUCTS_ERROR = '@alibaba/SEARCH_ALIBABA_PRODUCTS_ERROR';
export const FETCH_SINGLE_ITEM_DETAILS = '@alibaba/FETCH_SINGLE_ITEM_DETAILS';
export const FETCH_SINGLE_ITEM_DETAILS_SUCCESS = '@alibaba/FETCH_SINGLE_ITEM_DETAILS_SUCCESS';
export const FETCH_SINGLE_ITEM_DETAILS_ERROR = '@alibaba/FETCH_SINGLE_ITEM_DETAILS_ERROR';