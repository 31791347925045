import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import NavigationIcon from '@material-ui/icons/Navigation';
import {useTranslation} from "react-i18next";
import {Tooltip} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    top: '92%',
    left: '90%',
    margin: theme.spacing(1),
    background: theme.palette.systemColors.blue.primary,
    textTransform: 'unset',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },

  link: {
    color: 'white',
    display: "flex",
    alignItems: 'center',

    "&:active": {
      outline: 'none',
    }
  },

  tooltip: {
    fontSize: '0.8rem'
  }
}));

const email = process.env.REACT_APP_SUPPORT_EMAIL;

export default function MailButton() {
  const classes = useStyles();
  const {t} = useTranslation();
  return (
    <Tooltip title={t("Have a question? Send email to us")} aria-label="add" classes={{tooltip: classes.tooltip}}>
      <Fab
        variant="extended"
        size="medium"
        color="primary"
        aria-label="add"
        className={classes.fab}
      >
        <a href={`mailto:${email}`} className={classes.link}>
          <NavigationIcon className={classes.extendedIcon}/>
          {t('Ask us')}
        </a>
      </Fab>
    </Tooltip>
  )
}