import axios from 'axios';

const agent = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL
});

const token = localStorage.getItem('token');

if(token) {
  agent.defaults.headers.common['Authorization'] = `bearer ${token}`;
}

export function reloadToken(token) {
  agent.defaults.headers.common['Authorization'] = `bearer ${token}`;
}

export function removeToken() {
  delete agent.defaults.headers.common['Authorization'];
}

export function request({ method, url, data }) {
  return agent({
    method,
    url,
    data,
  })
}