import { KEYWORDS_TYPES } from '../types';

const initState = {
  keyword: '',
  loading: false,
  categories: [],
  page: 1,
  pageSize: 10,
  order: 'desc',
  orderBy: 'nicheAverage',
  selectedCategories: [],
  keywords: {
    results: {
      suggestions: []
    }
  },
  filterIsOpen: false,
  detailsIsOpen: false,
  detailsRow: {},
  filterSettings: {},
};

const keywordsReducer = (state = initState, { type, payload }) => {
  switch(type) {
    case KEYWORDS_TYPES.FETCH_KEYWORDS: {
      return {...state, loading: true}
    }
    case KEYWORDS_TYPES.FETCH_KEYWORDS_SUCCESS: {
      return {...state, keywords: payload, loading: false}
    }
    case KEYWORDS_TYPES.FETCH_KEYWORDS_ERROR: {
      return {...state, loading: false}
    }
    case KEYWORDS_TYPES.SET_USER_KEYWORD: {
      return {...state, keyword: payload.keyword}
    }
    case KEYWORDS_TYPES.GET_CATEGORIES: {
      return {...state, categories: payload.categories}
    }
    // case KEYWORDS_TYPES.GET_DETAILS: {
    //   return { ...state, details: payload.details }
    // }
    case KEYWORDS_TYPES.SET_SELECTED_CATEGORIES: {
      return {...state, selectedCategories: payload.categories}
    }
    case KEYWORDS_TYPES.SET_NICHE_TABLE_PARAMS: {
      return {...state, order: payload.order, orderBy: payload.orderBy, page: payload.page, pageSize: payload.pageSize}
    }
    case KEYWORDS_TYPES.SET_NICHE_TABLE_FILTER: {
      return { ...state, filterSettings: payload }
    }
    case KEYWORDS_TYPES.RESET_NICHE_TABLE_FILTER: {
      return { ...state, filterSettings: initState.filterSettings }
    }
    case KEYWORDS_TYPES.OPEN_NICHE_FINDER_FILTER: {
      return { ...state, filterIsOpen: true }
    }
    case KEYWORDS_TYPES.CLOSE_NICHE_FINDER_FILTER: {
      return { ...state, filterIsOpen: false }
    }
    case KEYWORDS_TYPES.OPEN_NICHE_FINDER_DETAILS: {
      return { ...state, detailsIsOpen: true, detailsRow: state.keywords.results.suggestions.find((suggestion) => suggestion.suggestion === payload.keywords) }
    }
    case KEYWORDS_TYPES.CLOSE_NICHE_FINDER_DETAILS: {
      return { ...state, detailsIsOpen: false }
    }
    default:
      return state;
  }
}

export default keywordsReducer;