import { all, call } from 'redux-saga/effects';
import { http } from './http';

const autoRestart = gen =>
  function* autoRestarting(...args) {
    while (true) {
      try {
        yield call(gen, ...args)
      } catch (error) {
        console.log(`Unhandled error in '${gen.name}'`, error);
      }
    }
  }

const rootSaga = autoRestart(function* root() {
  yield all([...http]);
})

export default rootSaga;
