export const IDEAL = 'ideal';
export const PAYPAL = 'paypal';
export const STRIPE_CARD = 'card';
export const SEPA_DEBIT = "sepa_debit";

export const STATUS_CANCELLED = "ENDED";

export const STRIPE = 'stripe';

export const PAYMENT_NAMES = {
  'sepa_debit': 'iDEAL',
  'card': 'Credit Card',
  'paypal': 'PayPal'
};

export const PAYMENT_METHOD_NAMES = [IDEAL, PAYPAL, STRIPE_CARD];

export const PAYMENT_METHODS_LIST = {
  [IDEAL]: {
    name: 'iDeal',
    type: IDEAL,
    imageName: 'ideal-method.svg',
  },
  [PAYPAL]: {
    name: 'PayPal',
    type: PAYPAL,
    imageName: 'paypal-method.svg',
  },
  [STRIPE_CARD]: {
    name: 'Credit Card',
    type: STRIPE_CARD,
    imageName: 'card-method.svg'
  }
};

