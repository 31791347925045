import { useEffect, useState } from "react";
import SessionTimeoutDialog from "../dialogs/SessionTimeoutDialog";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

export default function SessionTimeout() {
  const [openDialog, setOpenDialog] = useState(false);
  let warnTimeout;
  let logoutTimeout;

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const warn = () => {
    setOpenDialog(true);
  };

  const logout = () => {
    localStorage.removeItem("userLastActivity");
    localStorage.removeItem("autoPopupUserGuideClosedOnce");
    localStorage.removeItem("token");
    window.location.reload();
  };

  const setTimeouts = () => {
    warnTimeout = setTimeout(warn, 1500000);
    logoutTimeout = setTimeout(logout, 1800000);
  };

  const clearTimeouts = () => {
    if (warnTimeout) clearTimeout(warnTimeout);
    if (logoutTimeout) clearTimeout(logoutTimeout);
  };

  const resetTimeout = () => {
    clearTimeouts();
    setTimeouts();
    if (localStorage.getItem("token")) {
      localStorage.setItem("userLastActivity", new Date().getTime());
    } else {
      localStorage.removeItem("userLastActivity");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("userLastActivity")) {
      let currentTime = new Date().getTime();
      let diffBetweenCurrentAndBrowserClosedTime =
        currentTime - localStorage.getItem("userLastActivity");
      let totalCloseTime = diffBetweenCurrentAndBrowserClosedTime / 1000 / 60;

      if (totalCloseTime > 30) {
        localStorage.removeItem("userLastActivity");
        localStorage.removeItem("autoPopupUserGuideClosedOnce");
        localStorage.removeItem("token");
        window.location.reload();
      }
    }

    for (let event in events) {
      window.addEventListener(events[event], resetTimeout);
    }

    setTimeouts();

    return () => {
      for (let event in events) {
        window.removeEventListener(events[event], resetTimeout);
        clearTimeouts();
      }
    };
  }, []);

  return (
    <div className="SessionTimeout">
      {openDialog && (
        <SessionTimeoutDialog
          open={openDialog}
          handleCloseDialog={handleCloseDialog}
        />
      )}
    </div>
  );
}
