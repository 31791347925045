import { CALCULATOR_TYPES } from "../types";

const initState = {
    amazonCategories: [],
    bolCategories: [],
    fbaSizes: [],
    lvbSizes: [],
    bolPackageSizes: [],
    createSuccess: false,
    editSuccess: false,
    deleteSuccess: false,
    responseMessage: ""
};

const calculatorReducer = (state = initState, { type, payload, message }) => {
    switch (type) {
        case CALCULATOR_TYPES.FETCH_CATEGORIES: {
            return {
                ...state
            };
        }
        case CALCULATOR_TYPES.GET_AMAZON_CATEGORIES: {
            return {
                ...state, amazonCategories: payload.categories.amazonProductCategories
            }
        }
        case CALCULATOR_TYPES.GET_BOL_CATEGORIES: {
            return {
                ...state, bolCategories: payload.categories.bolProductCategories
            }
        }
        case CALCULATOR_TYPES.GET_FBA: {
            return {
                ...state, fbaSizes: payload.fbaSizes.fbaFullfilmentFees
            }
        }
        case CALCULATOR_TYPES.GET_LVB: {
            return {
                ...state, lvbSizes: payload.lvbSizes.lvbDeliveryCharges
            }
        }
        case CALCULATOR_TYPES.GET_BOL_PACKAGE_SIZES: {
            return {
                ...state, bolPackageSizes: payload.bolPackageSizes.packageSize
            }
        }
        case CALCULATOR_TYPES.CREATE_BOL_PACKAGE_SIZE_SUCCESS: {
            return {
                ...state, createSuccess: !state.createSuccess, responseMessage: ""
            }
        }
        case CALCULATOR_TYPES.EDIT_BOL_PACKAGE_SIZE_SUCCESS: {
            return {
                ...state, editSuccess: !state.editSuccess, responseMessage: ""
            }
        }
        case CALCULATOR_TYPES.DELETE_BOL_PACKAGE_SIZE_SUCCESS: {
            return {
                ...state, deleteSuccess: !state.deleteSuccess
            }
        }
        case CALCULATOR_TYPES.CREATE_BOL_PACKAGE_SIZE_ERROR: {
            return {
                ...state, responseMessage: message
            }
        }
        case CALCULATOR_TYPES.CLOSE_BOL_PACKAGE_SIZE_MODAL: {
            return {
                ...state, responseMessage: ""
            }
        }
        default:
            return state;
    }
}

export default calculatorReducer;
